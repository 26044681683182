import { Button } from "reactstrap";
import moment from "moment";

import {
  ADMIN,
  APPROVED,
  oldbaseURLmicroServiceFile,
  microServiceFileSeparator,
  DATE_FORMAT,
  INVESTIGATING,
  MANAGER,
  MODIFIED,
  MOH,
  REJECTED,
  ROLES_TO_SEE_STATUS,
  STATUSES,
  OPENED,
  SUBMITTED,
  VERIFIED,
} from "../constants";
import { findKey } from "../../resources";
import { GETFINANCIALSTATUS } from "../../GraphQl/query";
import { checkStatus } from "../functions";

import styles from "../../GenericList/GenericList.module.css";
import {
  cloneCol,
  deleteCol,
  errorCol,
  select,
  submitCol,
  updateCol,
  viewCol,
  organizationFiscalCodeCol,
  manageUserCol,
} from "../structureConstants";
import { ITEM } from "../../../navigation/Routes";
import i18n from "i18next";

export const handleToggled = (selectedRows, row, setRows) => {
  const { comment, userComment, rowId, attachmentName, attachmentPath } = row;
  const isToggled = selectedRows.map((item) => item.rowId).includes(rowId);
  if (isToggled) {
    const keyIndex = selectedRows.findIndex((item) => item.rowId === rowId);
    selectedRows = [
      ...selectedRows.slice(0, keyIndex),
      ...selectedRows.slice(keyIndex + 1),
    ];
    setRows(selectedRows);
  } else {
    setRows([
      ...selectedRows,
      ...[{ comment, userComment, rowId, attachmentName, attachmentPath }],
    ]);
  }
};

const getResult = (data, result) => {
  const dataToUpdate = result.filter((item) =>
    data.result.map((resItem) => resItem.rowId).includes(item.rowId)
  );
  let dataToInsert = [];
  result.forEach((element) => {
    if (!data.result.map((resItem) => resItem.rowId).includes(element.rowId)) {
      dataToInsert.push(element);
    }
  });
  // data.result.forEach(item => {
  //     if (typeof item.reasonCode === "object" && item.reasonCode !== null)
  //         return item.reasonCode;
  //     else
  //         item.reasonCode = {
  //             code: item.reasonCode ? item.reasonCode : "",
  //             description: item.description ? item.description : "",
  //             id: item.reasonCodeId ? item.reasonCodeId : "",
  //             attachmentName: item.attachmentName ? item.attachmentName : "",
  //             attachmentPath: item.attachmentPath ? item.attachmentPath : "",
  //             __typename: "ReasonCode"
  //         };
  // });
  const updatedRecord = data.result.map((finItem) => {
    const status = dataToUpdate.find(
      (statusItem) => statusItem.rowId === finItem.rowId
    );
    return { ...finItem, ...status };
  });
  return [...updatedRecord, ...dataToInsert];
};

const setApproveVar = (
  rowId,
  modelId,
  orgVersionId,
  status,
  comment,
  code,
  userComment,
  fileInput
) => {
  const statusKey = STATUSES.includes(status) ? status : findKey(status);
  const isArray = Array.isArray(rowId);
  const financialsStatuses = isArray ? rowId : [{ rowId: rowId }];
  const reasonCodeId = isNaN(parseInt(code)) ? 0 : parseInt(code);
  const statusComment = comment ? comment : "";
  const variables = {
    modelId: modelId,
    organizationVersionId: orgVersionId,
    financialsStatuses: financialsStatuses.map((item) => {
      return {
        rowId: item.rowId,
        comment: statusComment
          ? statusComment
          : item.comment
          ? item.comment
          : "",
        reasonCodeId: reasonCodeId,
        status: statusKey,
        userComment: userComment
          ? userComment
          : item.userComment
          ? item.userComment
          : "",
        modelId: modelId,
        organizationVersionId: orgVersionId,
        attachment: fileInput.fileName
          ? fileInput
          : {
              fileName: item.attachmentName,
              fileDownloadUri: item.attachmentPath,
            },
      };
    }),
  };
  return {
    variables,
    update: (proxy, { data: { result } }) => {
      const data = proxy.readQuery({
        query: GETFINANCIALSTATUS,
        variables: {
          modelId: parseInt(modelId),
          orgVersion: parseInt(orgVersionId),
        },
      });
      proxy.writeQuery({
        query: GETFINANCIALSTATUS,
        variables: {
          modelId: parseInt(modelId),
          orgVersion: parseInt(orgVersionId),
        },
        data: {
          ...data,
          result: getResult(data, result),
        },
      });
    },
  };
};

export const applyTranslate = (tableStrut, t, list, structureName) => {
  tableStrut.forEach((item, index) => {
    tableStrut[index].Header = t(item.Header);
  });

  list.forEach((item, index) => {
    // tentativi di aggirare problemi di sola lettura
    // list[index] = {...list[index]};
    // list[index] = Object.assign({}, list[index]);

    if (typeof list[index].status !== "undefined")
      list[index].status = t(item.status);
  });
};

export const handleSelectAll = (setRows, selectedRows, filteredRows) => {
  const filteredData = filteredRows.length
    ? filteredRows.map((item) => {
        const { userComment, comment, rowId } = item;
        return { userComment, comment, rowId };
      })
    : [];
  if (selectedRows.length) setRows([]);
  else setRows([...selectedRows, ...filteredData]);
};

export const changeStatus = async (
  setSpinnerStatus,
  doApprove,
  selectedRows,
  id,
  orgVersionId,
  status,
  comment,
  code,
  userComment,
  fileInput
) => {
  setSpinnerStatus(true);
  try {
    await doApprove(
      setApproveVar(
        selectedRows,
        id,
        orgVersionId,
        status,
        comment,
        code,
        userComment,
        fileInput
      )
    );
  } catch (e) {
    console.log(e);
  } finally {
    setSpinnerStatus(false);
  }
};

export const getColor = (status) => {
  switch (status) {
    case i18n.t(APPROVED):
      return "success";
    case i18n.t(INVESTIGATING):
      return "warning";
    case i18n.t(REJECTED):
      return "danger";
    case i18n.t(MODIFIED):
      return "primary";
    default:
      return "secondary";
  }
};

export function prepareValues(row, structureName) {
  const getAttachmentPath = () => {
    const isValidUrl =
      row.original.attachmentPath?.includes("http") ||
      row.original.attachmentPath?.includes(oldbaseURLmicroServiceFile) ||
      row.original.attachmentPath?.includes(microServiceFileSeparator);
    if (isValidUrl) return row.original.attachmentPath;
    else
      return row.original.attachmentPath
        ? `${oldbaseURLmicroServiceFile}/${row.original.attachmentPath}`
        : null;
  };
  const result =
    structureName === "Financial"
      ? {
          // aggiungo per gestione inibizione modifica commenti
          readOnly: row.original.previous_version === "1",

          rowId: row.original.rowId,
          modelId: row.original.model.id,
          orgVersionId: row.original.organizationVersion.id,
          status: row.original.status,
          comment: row.original.comment ? row.original.comment : "",
          reasonCode: row.original.reasonCode,
          reasonCodeId: row.original.reasonCodeId,
          userComment: row.original.userComment ? row.original.userComment : "",
          attachmentName: row.original.attachmentName?.slice(
            row.original.attachmentName.indexOf(microServiceFileSeparator) + 1
          ),
          attachmentPath: getAttachmentPath(),
        }
      : row.original;
  return result;
}

export const makeStatusMutation = async (
  doCloneOrg,
  row,
  instCode,
  setIsLoading,
  navigate
) => {
  try {
    // // PM 20241031 blocco temporaneo
    // alert(
    //   "Funzione temporaneamente non disponibile: contattare il supporto tecnico"
    // );
    // return;
    // // eslint-disable-next-line no-unreachable
    setIsLoading(true);
    await doCloneOrg({
      variables: { orgVersionId: row.original.orgVersionId },
      update: () => {
        navigate(-1);
      },
    });
  } catch (e) {
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};

export const cloneOrgVersionManager = async (doCloneOrg, row) => {
  // PM 20241031 blocco temporaneo
  // alert("Funzione temporaneamente non disponibile: contattare il supporto tecnico");
  // return;
  try {
    await doCloneOrg({
      variables: { orgVersionId: row.original.orgVersionId },
    });
  } catch (e) {
    console.log(e);
  }
};
export const reopenOrgVersion = async (doReopen, row) => {
  // PM 20241031 blocco temporaneo
  // alert("Funzione temporaneamente non disponibile: contattare il supporto tecnico");
  // return;
  try {
    await doReopen({
      variables: {
        orgVersionId: row.original.orgVersionId,
        newStatus: OPENED,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export function getChangeStatusBtn(
  toggleSubmitModalState,
  setValues,
  { row },
  role,
  doSubmitMutation,
  doCloneOrg,
  instCode,
  setIsLoading,
  toggleConfModalState,
  navigate
) {
  if (role === MOH && row.original.status === i18n.t(SUBMITTED))
    return (
      <Button
        color="success"
        onClick={() => {
          setValues(row.original);
          toggleConfModalState(true);
        }}
      >
        {i18n.t("chooseAction")}
      </Button>
    );
  // PM 20240427 Manager e Admin possono clonare solo se lo status è VERIFIED e non ci sono UO
  else if (
    [ADMIN, MANAGER].includes(role) &&
    row.original.status === i18n.t(VERIFIED) &&
    !row.original.structureInfo?.hasUnits
  )
    return (
      <Button
        className={styles.cloneBtn}
        color="success"
        onClick={() => {
          makeStatusMutation(
            doCloneOrg,
            row,
            row.original.instituteCode.toString(),
            setIsLoading,
            navigate
          );
        }}
      >
        {i18n.t("clone")}
      </Button>
    );
  else if (row.original.status === i18n.t(APPROVED))
    return (
      <Button color="secondary" disabled className={styles.confirmBtn}>
        {i18n.t(APPROVED)}
      </Button>
    );
  // PM 202102025 disabilitato bottone conferma
  else
    return (
      <Button
        disabled={checkStatus(row.original.status)}
        className={styles.confirmBtn}
        color="danger"
        onClick={() => {
          toggleSubmitModalState(true);
          setValues(row.original);
        }}
      >
        {i18n.t("confirmStatus")}
      </Button>
    );
}

export function getMomentDate(value) {
  const isValidDate = moment(value).isValid();
  const insertDate = isValidDate ? value : moment(value, DATE_FORMAT);
  return moment(insertDate).format(DATE_FORMAT);
}

export function setColMapper(
  structureName,
  schema,
  colMapper,
  userRole,
  isProjectManagerPage
) {
  if (structureName === "SubCategoryTotalDto") return schema.map(colMapper);
  else if (structureName === "Project")
    return !isProjectManagerPage
      ? [...schema, errorCol, submitCol].map(colMapper)
      : [...schema].map(colMapper);
  else if (structureName === "ProjectVersion")
    return [...schema].map(colMapper);
  else if (structureName === "OrganizationVersion")
    return [...schema, cloneCol].map(colMapper);
  else if (structureName === "Financial")
    return ROLES_TO_SEE_STATUS.includes(userRole)
      ? [viewCol, select, ...schema, updateCol, deleteCol].map(colMapper)
      : [viewCol, ...schema, updateCol, deleteCol].map(colMapper);
  else if (structureName === "Model")
    return [viewCol, ...schema, updateCol, cloneCol, deleteCol].map(colMapper);
  else if (structureName === "ValidationRule")
    return userRole === ADMIN
      ? [viewCol, ...schema, updateCol, deleteCol].map(colMapper)
      : [...schema].map(colMapper);
  else if (structureName === "StructureMOHStructure")
    return schema.map(colMapper);
  else if (structureName === "ProjectUnits")
    return [
      viewCol,
      ...schema,
      organizationFiscalCodeCol,
      manageUserCol,
      updateCol,
      deleteCol,
    ].map(colMapper);
  else return [viewCol, ...schema, updateCol, deleteCol].map(colMapper);
}

export function getBtnFunction(navigate, row) {
  navigate(`${ITEM}/${row.original.subcategory.id}`, {
    values: row.original,
    isCloneAction: true,
  });
}

export const getNonStatuses = (list) => {
  return list
    .filter(
      (item) =>
        item.status === "" || item.status === null || item.status === undefined
    )
    .map((filteredItem) => {
      return { rowId: filteredItem.rowId };
    });
};

export function getOnCompleteOptions(navigate) {
  return {
    update: (cache) =>
      Object.keys(cache.data.data).forEach((key) => {
        const found = key.match(/^Financial/);
        const found1 = key.match(/^ROOT_QUERY.financialsStatuses/);
        if (found || found1) cache.data.delete(key);
      }),
    onCompleted(data) {
      if (data) navigate(-1);
    },
  };
}

export function getFinancialRequest(
  requestFinancials,
  id,
  orgVersionId,
  userInfo,
  projectInfo,
  financialsApi,
  doImport,
  projectYear,
  orgVersionInstCode
) {
  switch (financialsApi) {
    case "DOIMPORT":
      return doImport({
        variables: {
          modelId: id,
          orgVersionId: orgVersionId,
          projectYear: projectYear,
        },
      });
    case "REQUESTFINANCIALS2021":
    case "REQUESTFINANCIALSV2":
      return requestFinancials({
        variables: {
          modelId: parseInt(id),
          orgVersionId: parseInt(orgVersionId),
          instituteCode:
            userInfo?.userInfo.role === "ADMIN"
              ? parseInt(orgVersionInstCode)
              : parseInt(userInfo?.userInfo.instituteCode),
          projectCode: parseInt(projectInfo?.externalProjectId),
        },
      });
    default:
      return null;
  }
}

export function setButton(
  link,
  label,
  structureName,
  isProjectManagerPage,
  navigate,
  toggleFunction
) {
  if (
    (structureName === "Project" && isProjectManagerPage) ||
    structureName === "ProjectVersion"
  ) {
    return ({ row }) => (
      <Button
        color={"info"}
        onClick={() => link(row.original.id, navigate, row.original)}
      >
        {i18n.t(label)}
      </Button>
    );
  } else if (structureName === "OrganizationVersion") {
    return ({ row }) => (
      <Button
        // disabled={row.original.status !== i18n.t("COMPLETED")}
        color={"info"}
        onClick={() => link(row.original, navigate)}
      >
        {i18n.t(label)}
      </Button>
    );
  } else if (structureName === "StructureMOHStructure") {
    return ({ row }) => (
      <Button
        color={"info"}
        onClick={() => {
          /*fix vista moh per 3 step*/ link(
            row.original.id,
            row.original.description,
            navigate
          );
        }}
      >
        {i18n.t(label)}
      </Button>
    );
  } else if (structureName === "Project") {
    return ({ row }) => (
      <Button
        color={"info"}
        onClick={() =>
          link(
            row.original.orgVersionId,
            row.original.structureId,
            row.original,
            navigate
          )
        }
      >
        {label}
      </Button>
    );
  } else if (structureName === "SubCategoryTotalDto") {
    return ({ row }) => (
      <Button
        color={"info"}
        onClick={() =>
          link(row.original.modelId, row.original.structureId, navigate)
        }
      >
        {label}
      </Button>
    );
  } else {
    // Luigi: non riesco a passare un nuovo nome di structureName
    // per via di problematiche apollo/graphql
    // quindi per il momento mi devo
    // accontentare di filtrare sulla label del bottone

    if (label === "FiltraProgettiPerStrutturaAnno")
      return ({ row }) => (
        <Button
          color={"info"}
          onClick={() => {
            link(row.original.id, row.original.description, navigate);
          }}
        >
          {"Mostra"}
        </Button>
      );
    else if (label === "Filtra")
      return ({ row }) => (
        <Button
          color={"info"}
          onClick={() => {
            link(row.original.id, row.original.description, navigate);
          }}
        >
          {"Mostra"}
        </Button>
      );
    else
      return ({ row }) => (
        <Button
          color={"info"}
          onClick={() => {
            link(row.original.id, navigate);
          }}
        >
          {label}
        </Button>
      );
  }
}

export function getDetailLink(
  col,
  headersName,
  linkFunction,
  detailsBtnLabel,
  secondLink,
  secondDetailsBtn,
  budgetLink,
  structureName,
  isProjectManagerPage,
  navigate,
  toggleBudgetState
) {
  if (col.accessor === "budget") {
    console.log("getDetailLink col.accessor :", col.accessor);
  }

  switch (col.accessor) {
    case headersName[0]:
      return setButton(
        linkFunction,
        detailsBtnLabel,
        structureName,
        isProjectManagerPage,
        navigate,
        null
      );
    case headersName[1]:
      return setButton(
        secondLink,
        secondDetailsBtn,
        structureName,
        isProjectManagerPage,
        navigate,
        null
      );
    case "budget":
      return setButton(
        budgetLink,
        "Buxdget",
        structureName,
        isProjectManagerPage,
        navigate,
        toggleBudgetState
      );
    default:
      return setButton(
        linkFunction,
        detailsBtnLabel,
        structureName,
        isProjectManagerPage,
        navigate,
        null
      );
  }
}
