import { Field } from "formik";
import moment from "moment";

import AutocompleteFromAPI from "../FormElements/AutocompleteFromAPI";
import { API_TYPES, TO_HIDE } from "../Helpers/constants";
import ApiSelectInput from "../FormElements/ApiSelectInput";
import RowDatePickerInput from "../FormElements/RowDatePickerInput";
import CalculatedInput from "../FormElements/CalculatedInput";
import RowSelectAutocompleteInput from "../FormElements/RowSelectAutocompleteInput";
import RowInput from "../FormElements/RowInput";
import { RowCheckInput } from "../FormElements";
import { getType } from "../Helpers/functions";

const setStringFieldType = (isLongerString, disabled) => {
  if (!disabled) {
    return "textarea";
  } else {
    return isLongerString ? "textarea" : "";
  }
};

export const generateFields = (
  structure,
  index,
  structureName,
  calculationFields,
  values
) => {
  const type = getType(structure, structureName);
  const disabled = values ? values.isDisabled : false;
  const isLongerString = values[structure.accessor]?.length >= 45;
  const isVisibleStatus = disabled
    ? null
    : structure.accessor === "status" || structure.accessor === "reasonCode"
    ? "hidden"
    : null;
  const label = structure.visible || isVisibleStatus ? "" : structure.Header;
  let result;
  switch (type) {
    case "String":
      if (structure.accessor === "validationResult")
        result = (
          <Field
            key={index}
            className="form-control-sm"
            formGroupClass=" mb-1"
            label={structure.visible ? "" : structure.Header}
            list={["ERROR", "WARNING"]}
            type={"hidden"}
            disabled={disabled}
            name={structure.accessor}
            labelcols={6}
            component={RowSelectAutocompleteInput}
          />
        );
      else
        result = (
          <Field
            key={index}
            className="form-control-sm"
            formGroupClass=" mb-1"
            label={label}
            name={structure.accessor}
            labelcols={6}
            component={RowInput}
            hidden={structure.visible ? structure.visible : isVisibleStatus}
            disabled={disabled}
            type={setStringFieldType(isLongerString, disabled)}
          />
        );
      break;
    case "Integer":
    case "Int":
      if (
        !TO_HIDE.includes(structure.Header) &&
        !(structureName === "ProjectUnits" && structure.accessor === "id")
      )
        result = (
          <Field
            key={index}
            className="form-control-sm"
            formGroupClass=" mb-1"
            label={structure.visible ? "" : structure.Header}
            name={structure.accessor}
            labelcols={6}
            component={CalculatedInput}
            calculation={calculationFields}
            type={structure.visible ? structure.visible : "number"}
            disabled={disabled}
          />
        );
      break;
    case "Boolean":
      result = (
        <Field
          key={index}
          className="form-control-sm"
          label={structure.visible ? "" : structure.Header}
          checkboxStyle={{ marginLeft: 0 }}
          name={structure.accessor}
          // type={structure.visible}
          disabled={disabled || structure.accessor === "previous_version"}
          labelcols={6}
          component={RowCheckInput}
        />
      );
      break;
    case "Date":
      if (structureName === "Financial") {
        result = (
          <Field
            key={index}
            className="form-control-sm"
            formGroupClass=" mb-1"
            label={structure.visible ? "" : structure.Header}
            type={structure.visible}
            disabled={disabled}
            name={structure.accessor}
            labelcols={6}
            component={RowDatePickerInput}
          />
        );
      } else result = <Field key={index} type="hidden" />;
      break;
    case "BigDecimal":
    case "Currency":
      if (Object.hasOwn(structure, "calculation")) {
        result = (
          <Field
            key={index}
            className="form-control-sm"
            formGroupClass=" mb-1"
            label={structure.visible ? "" : structure.Header}
            name={structure.accessor}
            labelcols={6}
            calculation={calculationFields}
            component={CalculatedInput}
            placeholder={structure.Header}
            disabled={true}
            type={structure.visible}
          />
        );
      } else
        result = (
          <Field
            key={index}
            className="form-control-sm"
            formGroupClass=" mb-1"
            label={structure.visible ? "" : structure.Header}
            name={structure.accessor}
            labelcols={6}
            calculation={calculationFields}
            type={structure.visible ? structure.visible : "number"}
            disabled={disabled}
            component={CalculatedInput}
          />
        );
      break;
    case "Dropdown":
      if (Object.hasOwn(structure, "api")) {
        result =
          structure.api !== "equipment" ? (
            <ApiSelectInput
              key={index}
              label={structure.visible ? "" : structure.Header}
              list={structure.options}
              name={structure.accessor}
              type={"hidden"}
              labelCols={6}
              disabled={disabled}
              className="form-control-sm"
              inputProps={{ defaultOption: values[structure.accessor] }}
              structureName={structure.api}
            />
          ) : (
            <Field
              key={index}
              className="form-control-sm"
              formGroupClass=" mb-1"
              label={structure.visible ? "" : structure.Header}
              list={structure.options}
              type={"hidden"}
              disabled={disabled}
              structureName={structure.api}
              name={structure.accessor}
              labelcols={6}
              component={AutocompleteFromAPI}
            />
          );
      } else {
        result = (
          <Field
            key={index}
            className="form-control-sm"
            formGroupClass=" mb-1"
            label={structure.visible ? "" : structure.Header}
            list={structure.options}
            type={"hidden"}
            disabled={disabled}
            name={structure.accessor}
            labelcols={6}
            component={RowSelectAutocompleteInput}
          />
        );
      }
      break;
    case "DropdownApi":
      result =
        structure.api !== "equipment" ? (
          <ApiSelectInput
            key={index}
            label={structure.visible ? "" : structure.Header}
            list={structure.options}
            name={structure.accessor}
            labelCols={6}
            inputProps={{ defaultOption: values[structure.accessor] }}
            disabled={disabled}
            className="form-control-sm"
            structureName={structure.api}
          />
        ) : (
          <Field
            key={index}
            className="form-control-sm"
            formGroupClass=" mb-1"
            label={structure.visible ? "" : structure.Header}
            list={structure.options}
            type={"hidden"}
            disabled={disabled}
            structureName={structure.api}
            name={structure.accessor}
            labelcols={6}
            component={AutocompleteFromAPI}
          />
        );
      break;
    case "SubCategory":
      result = (
        <ApiSelectInput
          key={index}
          labelCols={6}
          label={structure.visible ? "" : structure.Header}
          list={structure.options}
          type={"hidden"}
          structureName={type}
          inputProps={{ defaultOption: values[structure.accessor] }}
          disabled={disabled}
          name={structure.accessor}
        />
      );
      break;
    case "Model":
      result = (
        <ApiSelectInput
          key={index}
          labelCols={6}
          label={structure.visible ? "" : structure.Header}
          list={structure.options}
          type={"hidden"}
          structureName={type}
          inputProps={{ defaultOption: values[structure.accessor] }}
          disabled={disabled}
          name={structure.accessor}
        />
      );
      break;
    case "Organization":
      result = (
        <Field
          key={index}
          className="form-control-sm"
          formGroupClass=" mb-1"
          label={label}
          name="organization.description"
          labelcols={6}
          component={RowInput}
          hidden={structure.visible ? structure.visible : isVisibleStatus}
          disabled
          type={setStringFieldType(false, disabled)}
        />
      );
      break;
    default:
      result = <Field key={index} type="hidden" />;
  }
  return result;
};

export const generateInitValue = (items, structureName) => {
  const type = getType(items, structureName);
  switch (type) {
    case "Date":
      return moment().hour(12).minute(0).second(0).millisecond(0);
    case "Int":
    case "BigDecimal":
    case "Integer":
      return 0;
    case "String":
      return "";
    case "Boolean":
      return false;
    default:
      return "";
  }
};

export const prepareValues = (values, data, isFinancialPage) => {
  if (data && data.result && data.result.length > 0) {
    const apiName = API_TYPES.find((item) => Object.hasOwn(values, item));
    switch (apiName) {
      case "unit":
        values.unit = data.result.find(
          (item) => item.organization.description === values.unit
        )?.id;
        return values;
      default:
        return values;
    }
  }
  if (isFinancialPage && !values.status) delete values.status;
  return values;
};
