import { useState, useEffect } from "react";
import { ColumnFilter } from "./ColumnFilter";

export const GenericTableHeader = ({ headerGropus, styles, flexRender }) => {
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    const handleMouseUp = () => setIsResizing(false);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchend", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchend", handleMouseUp);
    };
  }, []);
  return (
    <thead>
      {headerGropus.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th
              key={header.id}
              colSpan={header.colSpan}
              style={{
                ...header.column.columnDef.headerStyle,
                width: `calc(var(--header-${header?.id}-size) * 1px)`,
                position: "relative",
                cursor: "pointer",
              }}
              onClick={(e) => {
                if (
                  e.target.tagName === "INPUT" ||
                  e.target.tagName === "SELECT" ||
                  isResizing
                )
                  return; // Prevent sorting when clicking inside an input
                header.column.getToggleSortingHandler()?.(e);
              }}
            >
              {header.isPlaceholder ? null : (
                <>
                  <div className={styles.truncate}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <div
                      {...{
                        onMouseDown: (e) => {
                          setIsResizing(true);
                          header.getResizeHandler()?.(e);
                        },
                        onTouchStart: (e) => {
                          setIsResizing(true);
                          header.getResizeHandler()?.(e);
                        },
                        onClick: (e) => e.stopPropagation(),
                        className: `resizer ${
                          header.column.getIsResizing() ? "isResizing" : ""
                        }`,
                        style: {
                          position: "absolute",
                          right: 0,
                          top: 0,
                          height: "100%",
                          width: "2px",
                          cursor: "col-resize",
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                        },
                      }}
                    ></div>
                  </div>
                  {header.column.getCanFilter() ? (
                    <div>
                      <ColumnFilter column={header.column} />
                    </div>
                  ) : null}
                </>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
