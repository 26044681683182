import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_MODELS } from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import { duplicateResponse } from "../Helpers/functions";

const ModelListContainer = () => {
  const { id } = useParams();
  const subCategoryId = parseInt(id);

  const { loading, error, data } = useQuery(GET_MODELS, {
    variables: { id: subCategoryId },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;

  const parentValue = data.result?.[0]?.subcategory?.description || "";

  const modelList = duplicateResponse(data.result);

  return (
    <GenericListContainer
      list={modelList}
      structureName={"Model"}
      parentName={"SubCategory"}
      btnLabel={"Create Model"}
      parentValue={subCategoryId ? parentValue : "Models"}
    />
  );
};

export default ModelListContainer;
