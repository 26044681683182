import { addDays, addMonths, isBefore, subDays } from "date-fns";

export const getMinStartDate = (list, projectVersion) => {
  if (
    (!list || list.length === 0) &&
    projectVersion.project &&
    projectVersion.project.startingDate
  ) {
    return new Date(projectVersion.project.startingDate);
  } else if (list.length > 0) {
    const lastInsertDate = list.reduce(function (prev, current) {
      return isBefore(
        new Date(prev.endPeriodDate),
        new Date(current.endPeriodDate)
      )
        ? prev
        : current;
    });
    return new Date(lastInsertDate.endPeriodDate);
  }
  return null;
};

export const getMaxStartDate = (projectVersion) => {
  if (projectVersion.endPeriodDate) {
    return subDays(new Date(projectVersion.endPeriodDate), 1);
  } else if (projectVersion.project && projectVersion.project.endingDate) {
    return subDays(new Date(projectVersion.project.endingDate), 1);
  }
  return null;
};

export const getDefaultStartDate = (pVers, list) => {
  // returns the furthest point in time (latest end date) represented within the objects.
  // Once the object with the latest endPeriodDate is identified,
  // the code calculates the date that comes one day after that latest end date.

  // CIOÈ A DIRE LA PROSSIMA RENDICONTAZIONE PARTE DA UN GIORNO DOPO QUELLA PIU IN LA
  // DELLE PRECEDENTI OVVERO QUELLE CHE APPAIONO NELLA LISTA

  if (
    (!list || list.length === 0) &&
    pVers.project &&
    pVers.project.startingDate
  ) {
    return new Date(pVers.project.startingDate);
  } else if (list.length > 0) {
    const lastInsertDate = list.reduce(function (prev, current) {
      return isBefore(
        new Date(prev.endPeriodDate),
        new Date(current.endPeriodDate)
      )
        ? prev
        : current;
    });
    return addDays(new Date(lastInsertDate.endPeriodDate), 1);
  }
  return null;
};

export const getDefaultEndDate = (pVers) => {
  // This function prioritizes using the project's extension date (if available)
  // for final versions and adds one month to it as the default end date.
  // It offers alternative options based on the presence of endPeriodDate or a project ending date.
  // If no relevant information is found, it defaults to the current date.

  // PERL LE INTERMEDIE IN GENERALE VIENE SCELTA LA DATA PREVISTA DI FINE PROGETTO

  if (
    pVers.versionType === "FINAL" &&
    pVers.project &&
    pVers.project.endingDate
  ) {
    const endDate = pVers.project.extensionDate
      ? pVers.project.extensionDate
      : pVers.project.endingDate;
    return addMonths(new Date(endDate), 1); // 1 mesi dopo la fine del progetto
  } else if (pVers.endPeriodDate) {
    return new Date(pVers.endPeriodDate);
  } else if (pVers.project && pVers.project.endingDate) {
    return new Date(pVers.project.endingDate);
  }

  return new Date();
};

export const getMinEndDate = (projectVersion) => {
  let minEndDate;
  if (projectVersion.startPeriodDate)
    minEndDate = addDays(new Date(projectVersion.startPeriodDate), 1);
  else if (projectVersion.project && projectVersion.project.startingDate)
    minEndDate = new Date(projectVersion.project.endingDate);
  else minEndDate = null;

  return minEndDate;
};

export const getMaxEndDate = (projectVersion) => {
  let maxEndDate;
  let dateArray;

  dateArray = [];
  if (projectVersion.endPeriodDate)
    dateArray.push(addDays(new Date(projectVersion.endPeriodDate), 1));

  if (projectVersion.project) {
    if (projectVersion.project.extensionDate)
      dateArray.push(
        subDays(new Date(projectVersion.project.extensionDate), 1)
      );

    if (projectVersion.project.endingDate)
      dateArray.push(subDays(new Date(projectVersion.project.endingDate), 1));
  }

  if (dateArray.length > 0) maxEndDate = Math.max(...dateArray);
  else maxEndDate = null;

  return maxEndDate;
};
