import { Spinner } from "reactstrap";
import { useQuery } from "@apollo/client";

import { GET_STRUCTURE_QUERY } from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import { STRUCTUREYEARS } from "../../navigation/Routes";
import { duplicateResponse } from "../Helpers/functions";

const TipologieProgetto = () => {
  const { loading, error, data } = useQuery(GET_STRUCTURE_QUERY);

  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return error.message;

  const structureList = duplicateResponse(data.result);
  const list = structureList;

  let structureListReduced = new Array();
  let result_set = new Set();

  list.forEach((item) => {
    const years_set = new Set(item.projects.map((project) => project.year));

    if (years_set.size > 0) {
      result_set.add({ structure_id: item.id, years: years_set });
      structureListReduced.push(item);
    }
  });

  structureListReduced.sort((a, b) =>
    a.description.toString().localeCompare(b.description.toString())
  );
  const years = Array.from(result_set);

  const anni = (id, desc, navigate) => {
    navigate(`${STRUCTUREYEARS}/${id}`, {
      state: {
        prj_desc: desc,
        years: years,
      },
    });
  };

  return (
    <GenericListContainer
      list={structureListReduced}
      structureName={"Structure"}
      detailsBtnLabel={"Filtra"}
      linkFunction={anni}
      secondLink={anni}
      secondDetailsBtn={"Filtra"}
      btnLabel={"Create Structure"}
    />
  );
};

export default TipologieProgetto;
