import { useQuery } from "@apollo/client";
import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";

import { duplicateResponse } from "../Helpers/functions";
import GenericListContainer from "../GenericList/GenericListContainer";
import { FIND_PROJECT, GET_PROJECT_UNITS } from "../GraphQl/query";

const ProjectUnitContainer = () => {
  const params = useParams();
  const projectId = params.projectId;

  //let missingheader3;
  let missingheader;

  const { data: u_dataProject } = useQuery(FIND_PROJECT, {
    variables: { projectId: parseInt(projectId) },
  });

  const { data, loading, error } = useQuery(GET_PROJECT_UNITS, {
    variables: { projectId: parseInt(projectId) },
    fetchPolicy: "network-only",
  });
  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;
  const projectList = duplicateResponse(data.result).map((row) => ({
    ...row,
    fiscalCode: row?.organization?.fiscalCode,
  }));
  if (
    u_dataProject?.result?.code !== undefined &&
    u_dataProject?.result?.instituteDescription !== undefined
  ) {
    missingheader =
      u_dataProject.result.code +
      "\n" +
      u_dataProject.result.instituteDescription;
  }

  return (
    <GenericListContainer
      list={projectList}
      structureName={"ProjectUnits"}
      missingheader={missingheader}
    />
  );
};

export default ProjectUnitContainer;
