import {
  getChangeStatusBtn,
  getDetailLink,
  getMomentDate,
  setColMapper,
} from "./genericListFunctions";
import {
  getCell,
  getCheckboxInput,
  getCheckboxInputAccessor,
  getClickableCell,
  getCloneButton,
  getClonedCell,
  getDeleteButton,
  getErrorButton,
  getUnitsButton,
  getBudgetButton,
  getImgCell,
  getUserManageButton,
  getLeftAlignCell,
  getLinkText,
  getNumberCell,
  getPercentageCell,
  getStatusBoxCell,
  getUpdateButton,
  getUrlText,
  getViewButton,
  getReopenButton,
} from "./genericListElements";
import { getType } from "../../Helpers/functions";
import { setSortMethod } from "../../Helpers/sortFunctions";
import {
  ADMIN,
  COMPLETED,
  ROLES_TO_SEE_STATUS,
  TO_HIDE_FROM_PROJECT,
  FLAG_COLS,
  FILTER_TYPES,
  TO_HIDE,
} from "../../Helpers/constants";

const headerStyle = { fontWeight: "bold" };

export const prepare = (
  setValues,
  toggleBudgetState,
  toggleModalState,
  doApprove,
  structureName,
  schema,
  detailsBtnLabel,
  linkFunction,
  secondLink,
  secondDetailsBtn,
  budgetLink,
  headersName,
  parentName,
  toggleConfModalState,
  isUploadEnabled,
  toggleSubmitModalState,
  status,
  setRows,
  selectedRows,
  toggleCommentModalState,
  userRole,
  spinnerStatus,
  t,
  doSubmitMutation,
  doCloneOrg,
  instCode,
  setIsLoading,
  navigate,
  isProjectManagerPage,
  doCloneOrgVersionManager,
  doReopenOrg,
  isConsolidated,
  isVerified,
  verifiedDate,
  toggleManageUserModalState
) => {
  const Cell = (row) => getCell(row);

  const clickableCell = (row) =>
    getClickableCell(
      spinnerStatus,
      row,
      t,
      setValues,
      structureName,
      toggleCommentModalState
    );

  const ImageCell = (row) => getImgCell(row);

  const leftAlign = (row) => getLeftAlignCell(row);

  const linkText = (row) => getLinkText(row);

  const isClonedCell = (row) => getClonedCell(row);

  const UrlCell = (row) => getUrlText(row);

  const DateCell = (row) => {
    return (
      <div style={{ marginTop: "7px" }}>
        {row.getValue() ? getMomentDate(row.getValue()) : ""}
      </div>
    );
  };

  const numberCell = (row) => getNumberCell(row);

  const percentageCell = (row) => getPercentageCell(row);

  const CellDeleteButton = (row) =>
    getDeleteButton(
      isUploadEnabled,
      status,
      row,
      t,
      toggleConfModalState,
      setValues
    );

  const actionBtn = ({ row }) => {
    // PM 20241102 verifiche per riapertura / clonazione OrganizationVersion
    if (
      structureName === "OrganizationVersion" &&
      row.original.status === t(COMPLETED)
    ) {
      // se progetto consolidato o confermato il bottone deve essere bloccato (stanno nello stesso stato per semplicità)
      if (isConsolidated) {
        const disabled = true;
        return getCloneButton(
          structureName,
          navigate,
          row,
          doCloneOrgVersionManager,
          t,
          disabled
        );
      }
      // se progetto non consolidato e non verificato posso riaprire
      if (!isConsolidated && !isVerified) {
        return getReopenButton(row, doReopenOrg, t);
      }
      // il progetto è verificato, la data di ultima modifica è precedente alla data di verifica: devo clonare
      if (row.original.lastModifiedDate < verifiedDate) {
        return getCloneButton(
          structureName,
          navigate,
          row,
          doCloneOrgVersionManager,
          t
        );
      }
      // il progetto è verificato, la data di ultima modifica è successiva alla data di verifica: posso riaprire
      return getReopenButton(row, doReopenOrg, t);
    }
    // altri casi (non OrganizationVersion?)
    return getCloneButton(
      structureName,
      navigate,
      row,
      doCloneOrgVersionManager,
      t
    );
  };

  const CellUpdateBtn = (row) =>
    getUpdateButton(
      isUploadEnabled,
      status,
      row,
      t,
      structureName,
      toggleModalState,
      navigate,
      setValues
    );

  const CellViewBtn = (row) =>
    getViewButton(structureName, toggleModalState, navigate, row, setValues);

  const ToErrorBtn = (row) => getErrorButton(row, secondLink, navigate);

  const ToUnitsBtn = (row) => getUnitsButton(row, secondLink, navigate);

  const ToBudgetBtn = (row) => getBudgetButton(row, toggleBudgetState);

  const OpenUserButton = (row) =>
    getUserManageButton(row, toggleManageUserModalState);

  // PM 20200506
  const getStatusButton = (row) => {
    return getChangeStatusBtn(
      toggleSubmitModalState,
      setValues,
      row,
      userRole,
      doSubmitMutation,
      doCloneOrg,
      instCode,
      setIsLoading,
      toggleConfModalState,
      navigate
    );
  };

  const CellCheckbox = (row) => getCheckboxInput(row);
  const CellCheckboxAccessor = (accessor) => (row) =>
    getCheckboxInputAccessor(accessor, row);

  const StatusBox = (row) => getStatusBoxCell(selectedRows, row, setRows);

  function getDetailCell(col) {
    return getDetailLink(
      col,
      headersName,
      linkFunction,
      detailsBtnLabel,
      secondLink,
      secondDetailsBtn,
      budgetLink,
      structureName,
      isProjectManagerPage,
      navigate,
      toggleBudgetState
    );
  }

  const colMapper = (col) => {
    const type = getType(col, structureName);
    const mappedCol = { ...col };

    switch (type) {
      case "Date":
        if (
          TO_HIDE_FROM_PROJECT.includes(mappedCol.accessor) &&
          structureName === "Project" &&
          !isProjectManagerPage
        )
          mappedCol.show = false;
        else if (
          structureName === "OrganizationVersion" &&
          col.accessor === "lastModifiedDate"
        ) {
          mappedCol.Cell = DateCell;
        } else if (structureName === "ValidationRule" && userRole !== ADMIN)
          mappedCol.show = false;
        else mappedCol.Cell = DateCell;
        if (structureName === "ValidationRule") mappedCol.size = 300;
        break;
      case "Int":
      case "Integer":
        if (
          TO_HIDE.includes(mappedCol.Header) ||
          mappedCol.visible === "hidden"
        ) {
          mappedCol.show = false;
        } else if (mappedCol.accessor === "ID") {
          mappedCol.minWidth = 50;
        } else if (structureName === "SubCategoryTotalDto")
          mappedCol.show = false;
        mappedCol.Cell = Cell;
        break;
      case "Delete":
        if (isUploadEnabled && structureName !== "ProjectUnits")
          mappedCol.Cell = CellDeleteButton;
        else mappedCol.show = false;
        break;
      case "Boolean":
        if (
          (isProjectManagerPage && mappedCol.accessor === "parent") ||
          structureName === "OrganizationVersion"
        )
          mappedCol.show = false;
        else if (mappedCol.accessor === "parent") {
          mappedCol.Cell = isClonedCell;
          mappedCol.size = 50;
        } else if (
          structureName !== "ValidationRule" &&
          mappedCol.accessor === "active"
        ) {
          mappedCol.filterVariant = FILTER_TYPES.boolean;
          mappedCol.filterFn = "booleanFilterFunction";
          mappedCol.Cell = CellCheckbox;
        } else if (structureName === "ValidationRule" && userRole !== ADMIN)
          mappedCol.show = false;
        else if (FLAG_COLS.includes(mappedCol.accessor)) {
          mappedCol.Cell = CellCheckboxAccessor(mappedCol.accessor);
          mappedCol.filterVariant = FILTER_TYPES.booleanDefault;
          mappedCol.filterFn = "booleanDefaultFilterFunction";
        } else mappedCol.Cell = CellCheckbox;
        break;
      case "Update":
        if (isUploadEnabled) mappedCol.Cell = CellUpdateBtn;
        else mappedCol.show = false;
        break;
      case "View":
        mappedCol.Cell = CellViewBtn;
        break;
      case "Error":
        mappedCol.Cell = ToErrorBtn;
        break;
      case "Submit":
        mappedCol.Cell = getStatusButton;
        break;
      case "String":
        if (mappedCol.accessor === "items") mappedCol.show = false;
        else if (isProjectManagerPage && mappedCol.accessor === "status")
          mappedCol.show = false;
        else if (isProjectManagerPage && mappedCol.accessor === "parent")
          mappedCol.Cell = leftAlign;
        // PM20210705
        else if (
          /*(structureName === "Project" && mappedCol.accessor === "description") || */ mappedCol.visible ===
          "hidden"
        )
          mappedCol.show = false;
        // PM20210705
        else if (structureName === "Project" && mappedCol.accessor === "cup")
          mappedCol.show = false;
        else if (mappedCol.accessor === "validationResult") {
          mappedCol.Cell = ImageCell;
          mappedCol.size = 150;
        } else if (
          mappedCol.accessor === "status" &&
          (structureName === "Financial" ||
            structureName === "SubCategoryTotalDto" ||
            structureName === "Project")
        ) {
          mappedCol.filterFn = "statusFilterFunction";
          mappedCol.filterVariant =
            structureName === "Project"
              ? FILTER_TYPES.orgStatusFilter
              : FILTER_TYPES.statusFilter;
          mappedCol.Cell =
            structureName === "Project" ? leftAlign : clickableCell;
        } else if (structureName === "ValidationRule") {
          mappedCol.Cell = leftAlign;
          if (
            mappedCol.accessor === "expression" ||
            mappedCol.accessor === "messagePrint"
          ) {
            userRole === ADMIN
              ? (mappedCol.Cell = leftAlign)
              : (mappedCol.show = false);
          } else {
            mappedCol.Cell = leftAlign;
            mappedCol.style = { whiteSpace: "normal" };
            mappedCol.size = 500;
          }
        } else if (
          mappedCol.accessor === "reasonCode" &&
          structureName === "Financial"
        ) {
          ROLES_TO_SEE_STATUS.includes(userRole)
            ? (mappedCol.Cell = leftAlign)
            : (mappedCol.show = false);
        } else if (mappedCol.accessor.includes("_url")) {
          mappedCol.Cell = UrlCell;
        } else mappedCol.Cell = leftAlign;
        break;
      case "BigDecimal":
        mappedCol.filterFn = "currencyFilterFunction";
        mappedCol.sortable = true;
        mappedCol.sortMethod = setSortMethod();
        if (mappedCol.visible === "hidden") mappedCol.show = false;
        else if (mappedCol.accessor.includes("_perc"))
          mappedCol.Cell = percentageCell;
        else mappedCol.Cell = numberCell;
        break;
      case "details":
        mappedCol.Cell = getDetailCell(mappedCol);
        mappedCol.enableColumnFilter = false;
        break;
      case "select":
        mappedCol.Cell = StatusBox;
        mappedCol.minWidth = 50;
        break;
      case null:
        if (mappedCol.Header === "budget") {
          mappedCol.Header = "";
          mappedCol.enableColumnFilter = false;
          mappedCol.Cell = ToBudgetBtn;
        } else if (mappedCol.type.ofType.kind === "LIST") {
          if (
            (mappedCol.Header === "organizations" &&
              structureName === "Project" &&
              !isProjectManagerPage) ||
            mappedCol.accessor === "structureSubcategories"
          ) {
            mappedCol.show = false;
          }
          mappedCol.Header = "";
          mappedCol.enableColumnFilter = false;
          mappedCol.Cell =
            !isProjectManagerPage || mappedCol.accessor === "organizations"
              ? getDetailCell(mappedCol)
              : ToUnitsBtn;
        }
        break;
      case parentName:
        mappedCol.show = false;
        break;
      case "Structure":
        mappedCol.show = false;
        break;
      case "OrgVersionValidation":
        mappedCol.show = false;
        break;
      case "Dropdown":
        mappedCol.Cell = leftAlign;
        break;
      case "User":
        mappedCol.show = false;
        break;
      case "Currency":
        // PM20210704
        // if (structureName === "Project")
        //     mappedCol.show = false;
        mappedCol.filterFn = "currencyFilterFunction";
        mappedCol.sortable = true;
        mappedCol.sortMethod = setSortMethod();
        mappedCol.Cell = numberCell;
        break;
      case "ValidationRule":
        mappedCol.Cell = getDetailCell(mappedCol);
        break;
      case "SubCategory":
        mappedCol.Cell = leftAlign;
        break;
      case "Model":
        mappedCol.Cell = linkText;
        break;
      case "Project":
        mappedCol.show = false;
        break;
      case "OrganizationVersionStatus":
        mappedCol.Cell = leftAlign;
        break;
      case "Organization":
        if (mappedCol.type.ofType.kind === "OBJECT") {
          if (structureName === "ProjectUnits") {
            mappedCol.accessor = "organization.description";
          }
        }
        mappedCol.Cell = leftAlign;
        break;
      case "ProjectVersion":
        mappedCol.show = false;
        break;
      case "clone":
        mappedCol.Header = "";
        mappedCol.Cell = actionBtn;
        break;
      case "manageUser":
        mappedCol.Header = "";
        mappedCol.Cell = OpenUserButton;
        break;
      default:
        mappedCol.Cell = Cell;
    }
    mappedCol.headerStyle = headerStyle;
    let filtered_columns;
    if (detailsBtnLabel === "FiltraProgettiPerStrutturaAnno") {
      filtered_columns = ["description", "validationRules"];
      if (filtered_columns.includes(mappedCol.accessor)) {
        mappedCol.show = true;
        if (mappedCol.accessor === "description") {
          mappedCol.Header = "Anno";
          mappedCol.Cell = leftAlign;
        } else if (mappedCol.accessor === "validationRules") {
          mappedCol.Header = "Mostra Progetti";
          mappedCol.enableColumnFilter = false;
          mappedCol.headerStyle = { verticalAlign: "text-top" };
        }
      } else {
        mappedCol.show = false;
      }
    }

    if (detailsBtnLabel === "Filtra") {
      filtered_columns = ["description", "validationRules"];

      if (filtered_columns.includes(mappedCol.accessor)) {
        mappedCol.show = true;

        if (mappedCol.accessor === "description") {
          mappedCol.Header = "Descrizione";
        } else if (mappedCol.accessor === "validationRules") {
          mappedCol.Header = "Mostra Anni Coinvolti";
          mappedCol.enableColumnFilter = false;
          mappedCol.headerStyle = { verticalAlign: "text-top" };
        }
      } else {
        mappedCol.show = false;
      }
    }

    if (detailsBtnLabel === "Progetti") {
      // TODO: MOH
      // console.log("IMPLEMENTARE");
    }

    return mappedCol;
  };

  if (structureName === "Project" && isProjectManagerPage) {
    const position = schema.findIndex(
      (obj) => obj.accessor === "organizations"
    );

    let new_button = JSON.parse(JSON.stringify(schema[position]));
    new_button.accessor = "budget";
    new_button.Header = "budget";

    schema = [
      ...schema.slice(0, position),
      new_button,
      ...schema.slice(position),
    ];
  }

  // ("ESEGUO MAPPAGGIO DELLO SCHEMA DI TABLELLA");

  return setColMapper(
    structureName,
    schema,
    colMapper,
    userRole,
    isProjectManagerPage
  );
};
