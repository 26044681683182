import i18n from "i18next";
import { useQuery } from "@apollo/client";
import { Spinner } from "reactstrap";

import { GET_CATEGORIES } from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import { SUBCATEGORIESLIST } from "../../navigation/Routes";
import { duplicateResponse } from "../Helpers/functions";

const CategoriesList = () => {
  const toSubCategoryList = (id, navigate) => {
    navigate(`${SUBCATEGORIESLIST}/${id}`);
  };

  const { loading, error, data } = useQuery(GET_CATEGORIES);
  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return error.message;
  const list = duplicateResponse(data?.result);

  return (
    <GenericListContainer
      list={list}
      structureName={"Category"}
      detailsBtnLabel={i18n.t("toSubcategories")}
      linkFunction={toSubCategoryList}
      btnLabel={"Create Category"}
    />
  );
};

export default CategoriesList;
