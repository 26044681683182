import {Field, FieldArray, Formik} from "formik";
import {Button, Container, Row, Col} from "reactstrap";
import DynamicRowInput from "./DynamicRowInput";
import DynamicSelectInput from "./DynamicSelectInput";
import {calcTypes} from "../Helpers/constants";

const initItem = [
    {
        "options": [],
    }
];
const setOptionArray = (options) => {
    return options.map(item => {
        return {"options": item}
    });
};
const DynamicInput = (props) => {
    const {list, type, parentValue, dispatch, options, isDisabled} = props;
    const items = options ? setOptionArray(options) : initItem;
    return (
        <Container fluid>
            <Formik
                initialValues={{items}}
                onSubmit={null
                }>
                {({values}) => (
                    <FieldArray
                        name="items"
                        render={arrayHelpers => (


                                    values.items.map((items, index) => (
                                                <Row key={index}>
                                                    <Col md={{ size: 6, offset: 3 }}>
                                                        {calcTypes.includes(type) && <Field label={`Parametro ${index + 1}`}
                                                                                            name={`items.${index}.options`}
                                                                                            component={DynamicSelectInput}
                                                                                            list={list}
                                                                                            dispatch={dispatch}
                                                                                            parentValue={parentValue}
                                                                                            type={type}
                                                                                            disabled={isDisabled}
                                                        />}
                                                        {type === "Dropdown" && <Field label={`Option ${index + 1}`}
                                                                                       name={`items.${index}.options`}
                                                                                       component={DynamicRowInput}
                                                                                       parentValue={parentValue}
                                                                                       dispatch={dispatch}
                                                                                       type={type}
                                                                                       disabled={isDisabled}

                                                        />}
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            type="button"
                                                            disabled={isDisabled}
                                                            onClick={() => {
                                                                arrayHelpers.remove(index);
                                                                dispatch({index: index, accessor: parentValue, type: type, action:"delete"})
                                                            }}
                                                        >
                                                            -
                                                        </Button>
                                                        <Button
                                                            disabled={isDisabled}
                                                            type="button"
                                                            onClick={() => arrayHelpers.push(initItem[0])}>+</Button>
                                                    </Col>
                                                </Row>
                                        )
                                    )

                        )}
                    />
                )}
            </Formik>
        </Container>
    )
};

export default DynamicInput

