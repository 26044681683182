import { useEffect, useContext } from "react";
import { Spinner } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import GenericListContainer from "../GenericList/GenericListContainer";
import { GET_LOGIN_DATA, GET_ORG_VERSION } from "../GraphQl/query";
import { ERRORS, SUBCATEGORIESTOTALS } from "../../navigation/Routes";
import { ProjectContext } from "../ProjectProvider";
import {
  getProjectList,
  getSubCategoryListParams,
  getVariables,
} from "./functions";

const ProjectList = () => {
  const { setProject } = useContext(ProjectContext);

  useEffect(
    () => {
      setProject("");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { state } = useLocation();
  const { structureId, year } = useParams();
  const { refetch, code, prj_desc } = state;

  const toSubCategoryList = (orgVersionId, structureId, project, navigate) => {
    navigate(`${SUBCATEGORIESTOTALS}/${orgVersionId}/${structureId}`, {
      state: getSubCategoryListParams(project),
    });
  };

  const toErrorList = (orgVersionId, navigate) => {
    navigate(`${ERRORS}/${orgVersionId}`);
  };

  const {
    data: auth,
    loading: authLoading,
    error: authError,
  } = useQuery(GET_LOGIN_DATA);

  const {
    data: list,
    loading: listLoading,
    error: listError,
  } = useQuery(GET_ORG_VERSION, {
    variables: { structureId, year },
    fetchPolicy: refetch ? "network-only" : "cache-first",
    skip: !auth, // Prevents running before auth data is available
  });

  if (authLoading || listLoading)
    return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (authError) return <p>Error: {authError.message}</p>;
  if (listError) return <p>Error: {listError.message}</p>;

  let projectList;

  const role = auth ? auth.userInfo.role : "";

  projectList = getProjectList(list, role).sort(
    (a, b) => b.orgVersionId - a.orgVersionId
  );

  const instCode = getVariables(auth);

  return (
    <GenericListContainer
      list={projectList}
      structureName={"Project"}
      btnLabel={"Create Project"}
      parentValue={"Progetti"}
      linkFunction={toSubCategoryList}
      detailsBtnLabel={"Categorie"}
      secondLink={toErrorList}
      code={code}
      instCode={instCode}
      prj_desc={prj_desc}
    />
  );
};

export default ProjectList;
