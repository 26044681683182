import { Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { NumericFormat } from "react-number-format";

import {
  cloneOrgVersionManager,
  getBtnFunction,
  getColor,
  handleToggled,
  prepareValues,
  reopenOrgVersion,
} from "./genericListFunctions";
import styles from "../../GenericList/GenericList.module.css";
import { checkStatus, getImage } from "../functions";
import { ITEM, MODELS } from "../../../navigation/Routes";
import { APPROVED, COMPLETED } from "../constants";

export function getCell(row) {
  const value = row.getValue ? row.getValue() : null;

  return <div style={{ marginTop: "7px" }}>{value}</div>;
}

export function getLeftAlignCell(row) {
  const value = row.getValue ? row.getValue() : null;
  return (
    <div style={{ textAlign: "left", marginTop: "7px" }}>
      <span title={value}>{value}</span>
    </div>
  );
}

export function getCenterAlignCell(row) {
  const value = row.getValue();
  return (
    <div style={{ textAlign: "center", marginTop: "7px" }}>
      <span title={value}>{value}</span>
    </div>
  );
}

export function getClickableCell(
  spinnerStatus,
  { row },
  t,
  setValues,
  structureName,
  toggleCommentModalState
) {
  const original = row.original;
  return spinnerStatus ? (
    <Spinner color={getColor(original?.status)} size="sm" />
  ) : (
    <Button
      className={styles.circleBtn}
      title={t(original?.status)}
      color={getColor(original?.status)}
      onClick={() => {
        setValues(prepareValues(row, structureName));
        toggleCommentModalState(true);
      }}
    />
  );
}

export function getImgCell(row) {
  const value = row.getValue();
  return (
    <div>
      <img height={25} src={getImage(value)} alt={value} title={value} />
    </div>
  );
}

export function getLinkText(row) {
  const original = row.row.original;
  const value = row.getValue();
  return (
    <div style={{ textAlign: "left", marginTop: "7px" }}>
      <Link to={`${MODELS}/${original?.subcategory_id}`}>
        <span title={value}>{value}</span>
      </Link>
    </div>
  );
}

export function getUrlText(row) {
  const value = row.getValue();
  return (
    <div style={{ textAlign: "left", marginTop: "7px" }}>
      <a href={`${value}`} target="_blank" rel="noreferrer">
        {value}
      </a>
    </div>
  );
}

export function getClonedCell(row) {
  const value = row.getValue();
  return (
    <div>
      <img height={25} src={getImage(value > 0 ? "Cloned" : "")} alt={value} />
    </div>
  );
}

export function getNumberCell(row) {
  const value = row.getValue ? row.getValue() : null;

  return (
    <div style={{ textAlign: "right", marginTop: "7px" }}>
      <NumericFormat
        thousandSeparator={"."}
        prefix={"€ "}
        decimalSeparator={","}
        value={parseFloat(value)}
        decimalScale={2}
        fixedDecimalScale={true}
        displayType={"text"}
        disabled
      />
    </div>
  );
}

export function getPercentageCell(row) {
  const value = row.getValue ? row.getValue() : null;
  return (
    <div style={{ marginTop: "7px" }}>
      <NumericFormat suffix={"%"} value={value} displayType={"text"} disabled />
    </div>
  );
}

export function getDeleteButton(
  isUploadEnabled,
  status,
  { row },
  t,
  toggleConfModalState,
  setValues
) {
  const original = row.original;

  return (
    <Button
      disabled={
        !isUploadEnabled ||
        status === i18n.t(APPROVED) ||
        checkStatus(status) ||
        original.status === t(APPROVED)
      }
      color={"danger"}
      className={styles.btn}
      onClick={() => {
        toggleConfModalState(true);
        setValues(original);
      }}
    >
      Canc.
    </Button>
  );
}

export function getCloneButton(
  structureName,
  navigate,
  row,
  doCloneOrgVersionManager,
  t,
  isDisabled = false
) {
  return (
    <Button
      color="success"
      disabled={
        isDisabled ||
        (structureName === "OrganizationVersion" &&
          row.original.status !== t(COMPLETED))
      }
      onClick={() => {
        structureName !== "OrganizationVersion"
          ? getBtnFunction(navigate, row)
          : cloneOrgVersionManager(doCloneOrgVersionManager, row);
      }}
    >
      {t("clone")}
    </Button>
  );
}

export function getReopenButton(row, doReopenOrg, t, isDisabled = false) {
  return (
    <Button
      color="success"
      disabled={isDisabled}
      onClick={() => reopenOrgVersion(doReopenOrg, row)}
    >
      {t("reopen")}
    </Button>
  );
}

export function getUpdateButton(
  isUploadEnabled,
  status,
  { row },
  t,
  structureName,
  toggleModalState,
  navigate,
  setValues
) {
  const original = row.original;

  return (
    <Button
      disabled={
        !isUploadEnabled ||
        status === i18n.t(APPROVED) ||
        checkStatus(status) ||
        original.status === t(APPROVED)
      }
      className={styles.btn}
      onClick={() => {
        if (structureName !== "Model") toggleModalState(true);
        else
          navigate(`${ITEM}/${original.subcategory?.id}`, {
            values: original,
          });
        setValues(original);
      }}
    >
      Mod.
    </Button>
  );
}

export function getViewButton(
  structureName,
  toggleModalState,
  navigate,
  { row },
  setValues
) {
  const original = row.original;

  return (
    <Button
      color="info"
      className={styles.btn}
      onClick={() => {
        if (structureName !== "Model") toggleModalState(true);
        else
          navigate(`${ITEM}/${original.subcategory?.id}`, {
            state: {
              values: original,
              isDisabled: true,
            },
          });
        setValues({ ...original, isDisabled: true });
      }}
    >
      Dett.
    </Button>
  );
}

export function getErrorButton({ row }, secondLink, navigate) {
  const original = row.original;

  return (
    <Button
      color={original.isVerificationFailed ? "warning" : "info"}
      onClick={() => secondLink(original.orgVersionId, navigate)}
    >
      Verifiche
    </Button>
  );
}

export function getUserManageButton({ row }, toggleManageUserModalState) {
  const original = row.original;

  if (!original.organization?.isExternal) return null;
  return (
    <Button
      color="info"
      className={styles.btn}
      onClick={() =>
        toggleManageUserModalState(original.organization?.instituteCode)
      }
    >
      Utente
    </Button>
  );
}

export function getUnitsButton({ row }, secondLink, navigate) {
  return (
    <Button color="info" onClick={() => secondLink(row.original.id, navigate)}>
      Unità
    </Button>
  );
}

// Budgets - Button
export function getBudgetButton({ row }, toggleBudgetState) {
  return (
    <Button
      color="info"
      onClick={() => {
        toggleBudgetState(row.original.id);
      }}
    >
      Budget{/*row.original?.id*/}
    </Button>
  );
}

export function getCheckboxInput({ row }) {
  return (
    <input
      className={styles.checkbox}
      readOnly
      type="checkbox"
      checked={row.original.active === true}
    />
  );
}

export const getCheckboxInputAccessor = (accessor, { row }) => {
  const original = row.original;
  return (
    <input
      className={styles.checkbox}
      readOnly
      type="checkbox"
      checked={
        original[accessor] == 1 ||
        original[accessor] === "true" ||
        original[accessor] === true
      }
    />
  );
};

export function getStatusBoxCell(selectedRows, { row }, setRows) {
  const original = row.original;

  return (
    <input
      type="checkbox"
      className={styles.checkbox}
      onChange={() => {
        handleToggled(selectedRows, original, setRows);
      }}
      checked={selectedRows.map((item) => item.rowId).includes(original?.rowId)}
    />
  );
}
