import { useQuery } from "@apollo/client";
import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";

import { GET_ERRORS } from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";

const ErrorListContainer = () => {
  const { orgVersionId } = useParams();
  const { loading, error, data } = useQuery(GET_ERRORS, {
    variables: { orgVersionId },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;

  let clone = JSON.parse(JSON.stringify(data));
  let fresh = { validationErrors: new Array() };

  for (const error of clone.validationErrors) {
    if (error?.validationMessage?.length) {
      const multipleMessages = error.validationMessage.split("\n");

      for (const message of multipleMessages) {
        let newRowDetail = { ...error };
        newRowDetail.validationRule.message = "‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ◉‎ ‎ " + message;
        fresh.validationErrors.push(newRowDetail);
      }
    } else {
      fresh.validationErrors.push({ ...error });
    }
  }

  let errorList = fresh.validationErrors.map(function (items) {
    const validationResult = items.validationResult;

    return { ...items.validationRule, validationResult };
  });

  return (
    <GenericListContainer list={errorList} structureName={"ValidationRule"} />
  );
};

export default ErrorListContainer;
