import { useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
} from "reactstrap";
import { useMutation, useQuery } from "@apollo/client";

import {
  LOGIN,
  SUBCATEGORIESTOTALS,
  FINANCIAL,
  UPLOAD,
  PRJ,
  CATEGORIESLIST,
  STRUCTURE,
  MODELS,
  MANAGERPAGE,
  MOHPAGE,
  PRJS,
} from "../../navigation/Routes";
import { client, DOWNLOAD_URL } from "../GraphQl";
import logo from "../../assets/banner1.png";
import styles from "../GenericList/GenericList.module.css";
import { GET_LOGIN_DATA } from "../GraphQl/query";
import ConfirmationModal from "../ConfirmationModal";
import { VERIFY } from "../GraphQl/mutation";
import { capitalize, checkStatus } from "../Helpers/functions";
import {
  ADMIN,
  MANAGER,
  MOH,
  ORG_VERSION_STATUSES,
} from "../Helpers/constants";
import { ProjectContext } from "../ProjectProvider";
import { CATEGORIE_X_SINTESI_RF } from "../Helpers/constants";

const ROLES_TO_SEE_STATUS = [MOH, ADMIN];

const NavbarComponent = () => {
  const navigate = useNavigate();

  const { pathname, state } = useLocation();

  const T_STATUSES = useMemo(
    () => ORG_VERSION_STATUSES.map((status) => i18n.t(status)),
    []
  );
  const token = localStorage.getItem("token");
  const [message, setMessage] = useState("");
  const [values, setValues] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [confModalState, toggleConfModalState] = useState(false);
  const [doVerification] = useMutation(VERIFY, {
    onCompleted(data) {
      setError(data.verifyOrganizationVersion);
      data.verifyOrganizationVersion
        ? setMessage("La verifica ha avuto esito positivo")
        : setMessage(
            "La verifica non ha avuto esito positivo, seleziona il link sottostante per vedere errori e avvisi"
          );
      setIsLoading(true);
    },
  });
  const { data: auth } = useQuery(GET_LOGIN_DATA);

  const loggedIn = (auth || {}).isLoggedIn;
  const name = (auth || {}).userInfo ? (auth || {}).userInfo.name : "";
  const surname = (auth || {}).userInfo ? (auth || {}).userInfo.surname : "";
  const userRole = (auth || {}).userInfo ? (auth || {}).userInfo.role : "";
  const totalsPage = pathname.match(RegExp(SUBCATEGORIESTOTALS));
  const financialsPage = pathname.match(RegExp(FINANCIAL));

  const projectPage = pathname.match(RegExp(PRJ + "$"));
  const MOHPage = pathname.match(RegExp(MOHPAGE));
  const orgVersionId = totalsPage
    ? pathname.split("/")[2]
    : financialsPage
    ? pathname.split("/")[3]
    : "";
  const structureId = totalsPage ? pathname.split("/")[3] : "";

  const ShowReportSintesiRF =
    !!totalsPage && CATEGORIE_X_SINTESI_RF.includes(+structureId);

  const modelId = financialsPage ? pathname.split("/")[2] : "";

  const showConfig = userRole === ADMIN || userRole === MANAGER;
  const { setProject } = useContext(ProjectContext);

  let instituteCode;

  instituteCode = (auth || {}).userInfo
    ? (auth || {}).userInfo.instituteCode
    : "";

  if (userRole !== "PROJECT") instituteCode = -1;

  const onLogout = () => {
    setProject("");
    localStorage.clear();
    client.resetStore();
    navigate(LOGIN);
  };
  return (
    <Container fluid>
      <Col hidden>
        <ConfirmationModal
          isModalOpen={confModalState}
          toggleModal={toggleConfModalState}
          message={message}
          label={"Verifica"}
          values={values}
          setValues={setValues}
          isError={!error}
          orgVersionId={orgVersionId}
          isLoading={isLoading}
        />
      </Col>
      <Navbar light expand="md" className={styles.navbar}>
        <NavbarBrand tag={Link} to={`${PRJS}/${instituteCode}`}>
          <Nav className="flex-column" navbar>
            <NavItem>
              <img
                src={logo}
                className={styles.logo}
                alt="Rendicontazione Finanziaria"
              />
            </NavItem>
          </Nav>
        </NavbarBrand>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <div className={styles.project}>&nbsp;</div>
          </NavItem>
        </Nav>

        {loggedIn && false && (
          <Nav className="mr-auto" navbar>
            <NavItem className={styles.left}>
              <div className={styles.user}>Ruolo: {capitalize(userRole)}</div>
            </NavItem>
          </Nav>
        )}

        <Nav className="ml-auto" navbar>
          {totalsPage && loggedIn && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className={styles.text}>Scarica</span>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  href={`${DOWNLOAD_URL}${orgVersionId}/model/${modelId}?token=${token}`}
                  target="_blank"
                >
                  {i18n.t("downloadExcel")}
                </DropdownItem>
                <DropdownItem divider />
                {/* PM 20200506 */}
                <DropdownItem
                  href={`${DOWNLOAD_URL}${orgVersionId}/pdf/${modelId}?token=${token}`}
                  target="_blank"
                >
                  {i18n.t("downloadPdf")}
                </DropdownItem>

                {(ROLES_TO_SEE_STATUS.includes(userRole) ||
                  !T_STATUSES.includes(state.status)) && (
                  <div>
                    <DropdownItem divider />
                    <DropdownItem
                      href={`${DOWNLOAD_URL}${orgVersionId}/financials-status/?token=${token}`}
                      target="_blank"
                    >
                      {i18n.t("downloadStatus")}
                    </DropdownItem>
                  </div>
                )}

                {ShowReportSintesiRF && (
                  <div>
                    <DropdownItem divider />
                    <DropdownItem
                      href={`${DOWNLOAD_URL}${orgVersionId}/reportSintesiRF/?token=${token}`}
                      target="_blank"
                    >
                      {i18n.t("reportSintesiRF")}
                    </DropdownItem>
                  </div>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {totalsPage && loggedIn && (
            <NavItem hidden={checkStatus(state?.status)}>
              <NavLink
                href="#"
                onClick={async () => {
                  try {
                    setIsLoading(false);
                    toggleConfModalState(true);
                    await doVerification({
                      variables: { orgVersionId: parseInt(orgVersionId) },
                    });
                  } catch (e) {
                    setMessage(e);
                    setIsLoading(true);
                  }
                }}
              >
                <span className={styles.text}>Verifica</span>
              </NavLink>
            </NavItem>
          )}
          {financialsPage && (
            <NavItem className={styles.left}>
              <NavLink
                href={`${DOWNLOAD_URL}${orgVersionId}/model/${modelId}?token=${token}`}
                target="_blank"
              >
                <span className={styles.text}>Scarica Excel</span>
              </NavLink>
            </NavItem>
          )}
          {financialsPage && (
            <NavItem className={styles.left}>
              <NavLink
                hidden={
                  checkStatus(state?.status) ||
                  (state?.isCloned && state?.structureId != 11)
                }
                tag={Link}
                to={`${UPLOAD}/${modelId}/${orgVersionId}`}
              >
                <span className={styles.text}>Carica da Excel</span>
              </NavLink>
            </NavItem>
          )}
          {showConfig && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className={styles.text}>{i18n.t("config")}</span>
              </DropdownToggle>
              {userRole === ADMIN && (
                <DropdownMenu end>
                  <DropdownItem tag={Link} to={`${CATEGORIESLIST}`}>
                    {i18n.t("toCategories")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag={Link} to={`${STRUCTURE}`}>
                    {i18n.t("toStructure")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag={Link} to={`${MODELS}`}>
                    {i18n.t("toModels")}
                  </DropdownItem>
                </DropdownMenu>
              )}
              {userRole === MANAGER && (
                <DropdownMenu end>
                  <DropdownItem tag={Link} to={`${MANAGERPAGE}`}>
                    {i18n.t("managerPage")}
                  </DropdownItem>
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          )}

          {loggedIn &&
            (!projectPage || (projectPage && userRole === "MOH")) &&
            !MOHPage && (
              <NavItem className={styles.left}>
                <Button onClick={() => navigate(-1)}>Indietro</Button>
              </NavItem>
            )}
          {loggedIn && (
            <>
              <NavItem className={styles.left}>
                <div className={styles.user}>
                  {name} {surname}
                </div>
                {/*<div className={styles.user}>{'(' + capitalize(userRole) + ')'}</div>*/}
              </NavItem>
              <NavItem className={styles.left}>
                <div>
                  <Button color="primary" onClick={onLogout}>
                    Logout
                  </Button>
                </div>
              </NavItem>
            </>
          )}
        </Nav>
      </Navbar>
    </Container>
  );
};

NavbarComponent.propTypes = {
  isAuth: PropTypes.bool,
};

NavbarComponent.defaultProps = {
  isAuth: false,
};

export default NavbarComponent;
