import i18n from "i18next";
import { Input } from "reactstrap";

import {
  APPROVED,
  INVESTIGATING,
  REJECTED,
  MODIFIED,
  CONSOLIDATED,
  OPENED,
  SUBMITTED,
  VERIFIED,
  SNAPSHOTTED,
  COMPLETED,
  FILTER_EMPTY_STRING,
} from "./constants";

export const defaultFilterFunction = (row, columnId, filterValue) => {
  const cellValue = String(row.getValue(columnId) || "").toLowerCase();
  if (filterValue === FILTER_EMPTY_STRING) {
    return !cellValue;
  }
  const filterText = String(filterValue || "").toLowerCase();
  return cellValue.includes(filterText);
};

export const currencyFilterFunction = (row, columnId, filterValue) => {
  const cellValue = String(row.getValue(columnId) || "").toLowerCase();
  // const id = filter.pivotId || filter.id;
  const value = String(filterValue).replace(",", ".");
  if (filterValue.includes("<") && !filterValue.includes("|")) {
    const limit = value.replace("<", "").trim();
    return cellValue !== undefined && cellValue !== null
      ? parseFloat(cellValue) <= parseFloat(limit)
      : false;
  } else if (filterValue.includes(">") && !filterValue.includes("|")) {
    const limit = value.replace(">", "").trim();
    return cellValue !== undefined && cellValue !== null
      ? parseFloat(cellValue) >= parseFloat(limit)
      : false;
  } else if (filterValue.includes("|")) {
    const rightLimit = filterValue
      .substr(filterValue.indexOf("|") + 1)
      .replace("<", "")
      .trim();
    const leftLimit = filterValue
      .substr(0, filterValue.indexOf("|"))
      .replace("<", "")
      .trim();
    return cellValue !== undefined && cellValue !== null
      ? parseFloat(leftLimit) <= parseFloat(cellValue) &&
          parseFloat(cellValue) <= parseFloat(rightLimit)
      : false;
  } else
    return cellValue !== undefined && cellValue !== null
      ? parseFloat(cellValue) === parseFloat(value)
      : false;
};

export const statusFilterFunction = (row, columnId, filterValue) => {
  const cellValue = row.getValue(columnId);
  if (filterValue === "all") {
    return true;
  }
  if (filterValue === i18n.t("noStatus")) {
    return cellValue === null || cellValue === undefined || cellValue === "";
  }
  return String(cellValue)
    .toLowerCase()
    .includes(String(filterValue).toLowerCase());
};

export const booleanFilterFunction = (row, columnId, filterValue) => {
  const cellValue = row.getValue(columnId);

  if (filterValue === "all") {
    return true;
  }
  return cellValue !== null || cellValue !== undefined
    ? cellValue.toString() === filterValue
    : "";
};

export const booleanDefaultFilterFunction = (row, columnId, filterValue) => {
  const cellValue = row.getValue(columnId);

  if (!filterValue) {
    return true;
  }
  let val = cellValue || "0";
  if (val === "false") val = "0";
  if (val === "true") val = "1";
  return val === filterValue;
};

export const statusFilter = ({ filter, setFilter, column }) => (
  <select
    onChange={(event) => setFilter(column.id, event.target.value)}
    style={{ width: "100%" }}
    value={filter ? filter.value : "all"}
  >
    <option value="all" />
    <option value={i18n.t(APPROVED)}>{i18n.t("app")}</option>
    <option value={i18n.t(REJECTED)}>{i18n.t("rej")}</option>
    <option value={i18n.t(INVESTIGATING)}>{i18n.t("inv")}</option>
    <option value={i18n.t(MODIFIED)}>{i18n.t("mod")}</option>
    <option value={null}>{i18n.t("noStatus")}</option>
  </select>
);

export const StatusFilter = ({ filterValue, column }) => {
  return (
    <Input
      type="select"
      onChange={(event) => column.setFilterValue(event.target.value)}
      style={{ width: "100%" }}
      value={filterValue ? filterValue : "all"}
    >
      <option value="all" />
      <option value={i18n.t(APPROVED)}>{i18n.t("app")}</option>
      <option value={i18n.t(REJECTED)}>{i18n.t("rej")}</option>
      <option value={i18n.t(INVESTIGATING)}>{i18n.t("inv")}</option>
      <option value={i18n.t(MODIFIED)}>{i18n.t("mod")}</option>
      <option value={null}>{i18n.t("noStatus")}</option>
    </Input>
  );
};

export const OrgStatusFilter = ({ filterValue, column }) => {
  return (
    <Input
      type="select"
      onChange={(e) => column.setFilterValue(e.target.value)}
      style={{ width: "100%" }}
      value={filterValue ? filterValue : ""}
    >
      <option value="" />
      <option value={i18n.t(OPENED)}>{i18n.t("open")}</option>
      <option value={i18n.t(SUBMITTED)}>{i18n.t("conf")}</option>
      <option value={i18n.t(VERIFIED)}>{i18n.t("ver")}</option>
      <option value={i18n.t(APPROVED)}>{i18n.t("Approvato")}</option>
      <option value={i18n.t(SNAPSHOTTED)}>
        {i18n.t("Situazione corrente")}
      </option>
      <option value={i18n.t(CONSOLIDATED)}>{i18n.t("Consolidato")}</option>
      <option value={i18n.t(COMPLETED)}>{i18n.t("Completato")}</option>
    </Input>
  );
};

export const BooleanFilterNew = ({ filterValue, column }) => (
  <Input
    type="select"
    onChange={(event) => column.setFilterValue(event.target.value)}
    style={{ width: "100%" }}
    value={filterValue ? filterValue : "all"}
  >
    <option value="all" />
    <option value={"true"}>{i18n.t("active")}</option>
    <option value={"false"}>{i18n.t("inactive")}</option>
  </Input>
);

export const BooleanDefaultFilterNew = ({ filterValue, column }) => (
  <Input
    type="select"
    onChange={(event) => column.setFilterValue(event.target.value)}
    style={{ width: "100%" }}
    value={filterValue ? filterValue : ""}
  >
    <option value="" />
    <option value={"1"}>Sì</option>
    <option value={"0"}>No</option>
  </Input>
);
