import { ROLES_TO_SEE_STATUS, rolesEnum } from "../Helpers/constants";

export function getProjectList(list, role) {
  return list
    ? list.organizationVersions
        .map((items) => ({
          ...items.version.project,
          status: items.status,
          instituteDescription: ROLES_TO_SEE_STATUS.includes(role)
            ? items.organization.description
            : items.version.project.instituteDescription,
          projectId: items.version.project.id,
          externalProjectId: items.version.project.projectId,
          isVerificationFailed: !!items.orgVersionValidations.find(
            (item) =>
              item.validationResult === "WARNING" ||
              item.validationResult === "ERROR"
          ),
          lastModifiedDate: items.lastModifiedDate,
          parent: items.parent,
          orgVersionId: items.id,
          organizationId: items.organization.id,
          isExternal: items.organization.isExternal,
          orgVersionInstCode: items.organization.instituteCode,
          structureId: items.version.project.structure.id,
          structureInfo: items.version.project.structure,
          startingDate: items.version.startPeriodDate,
          endingDate: items.version.endPeriodDate,
          description: items.version.description,
        }))
        .map(({ __typename, structure, ...item }) => item)
    : list;
}

export const getSubCategoryListParams = (project) => ({
  status: project.status,
  isCloned: !!project.parent,
  projectCode: project.code,
  projectId: project.id,
  isExternal: project.isExternal,
  projectYear: project.year,
  externalProjectId: project.externalProjectId,
  organizationId: project.organizationId,
  structureInfo: project.structureInfo,
  orgVersionInstCode: project.orgVersionInstCode,
  coFinancing: project.coFinancing,
  instituteDescription: project.instituteDescription,
});

export const getVariables = (auth) => {
  const { role, instituteCode } = auth.userInfo;
  switch (role) {
    case rolesEnum.ADMIN:
    case rolesEnum.MOH:
      return null;
    case rolesEnum.PROJECT:
    case rolesEnum.USER:
      return { instituteCode };
    default:
      return null; // Handle unexpected roles safely
  }
};
