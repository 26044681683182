import { Spinner } from "reactstrap";
import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";

import {
  GET_FINANCIAL,
  GET_PRJ_INFO,
  GET_UNITS,
  GETFINANCIALSTATUS,
  MODEL_DESCR,
} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import { duplicateResponse } from "../Helpers/functions";
import {
  prepareFinancialList,
  prepareUnitList,
  getTotals,
  getPreviousVersion,
} from "./functions";

const FinancialContainer = () => {
  const { state } = useLocation();
  const { id, orgVersionId } = useParams();

  const modelId = parseInt(id);
  const orgVersion = parseInt(orgVersionId);
  const status = state.status;
  const isCloned = state.isCloned;
  const structureId = state.structureId;
  const isExternal = state.isExternal;

  const { data: projectInfo, loading: loadingInfo } = useQuery(GET_PRJ_INFO);

  const { data: unitsList, loading: loadingUnits } = useQuery(GET_UNITS, {
    variables: { projectId: projectInfo?.projectId },
    skip: !projectInfo, // Prevents execution until projectInfo is available
  });

  const { data: modelDescr, loading: loadingModel } = useQuery(MODEL_DESCR, {
    variables: { modelId, orgVersion },
    skip: !unitsList,
  });

  const { data: finStatus, loading: loadingFinStatus } = useQuery(
    GETFINANCIALSTATUS,
    {
      variables: { modelId, orgVersion },
      skip: !modelDescr,
    }
  );

  const {
    data: financialData,
    loading: loadingFinancial,
    error,
  } = useQuery(GET_FINANCIAL, {
    variables: { id: modelId, orgVersion },
    fetchPolicy: "cache-and-network",
    skip: !finStatus,
  });

  if (
    loadingInfo ||
    loadingUnits ||
    loadingModel ||
    loadingFinStatus ||
    loadingFinancial
  ) {
    return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  }
  if (error) return <div>{error.message}</div>;

  const clonedList = duplicateResponse(financialData?.result);
  const clonedFinStatuses = duplicateResponse(finStatus?.result);
  const list = prepareFinancialList(clonedList, clonedFinStatuses);
  const finList = prepareUnitList(list, unitsList?.result);
  const total_cost = getTotals(list, "total_cost");
  const eligible_cost = getTotals(list, "eligible_cost");
  const previousList = getPreviousVersion(list);
  const total_previous_cost = getTotals(previousList, "total_cost");
  const eligible_previous_cost = getTotals(previousList, "eligible_cost");
  const parentName = modelDescr?.models?.[0]?.description || "";
  const isUploadEnabled = modelDescr?.models?.[0]?.uploadEnabled || false;

  return (
    <GenericListContainer
      list={finList}
      totalCost={total_cost}
      totalCurrentCost={total_cost - total_previous_cost}
      eligibleCost={eligible_cost}
      eligibleCurrentCost={eligible_cost - eligible_previous_cost}
      structureName={"Financial"}
      structureId={structureId}
      parentName={"Model"}
      parentValue={parentName}
      isUploadEnabled={isUploadEnabled}
      btnLabel={"Inserisci"}
      status={status}
      isCloned={isCloned}
      isExternal={isExternal}
    />
  );
};

export default FinancialContainer;
