import GenericListContainer from "../GenericList/GenericListContainer";
import { GET_LOGIN_DATA, GET_PROJECT } from "../GraphQl/query";
import { useQuery } from "@apollo/client";
import { duplicateResponse } from "../Helpers/functions";
import { PRJVERSION, PRJUNITS, BUDGETS } from "../../navigation/Routes";
import { Spinner } from "reactstrap";

const toProjectVersion = (projectId, navigate, project) => {
  navigate(`${PRJVERSION}/${projectId}`, {
    state: {
      projectCode: project.code,
      missingheader: project.code + "\n" + project.instituteDescription,
    },
  });
};

const toProjectUnits = (id, navigate) => {
  navigate(`${PRJUNITS}/${id}`);
};

const toBudgetPopUp = (id, navigate) => {
  alert("id: " + id);
  navigate(`${BUDGETS}/${id}`);
};

const ProjectMangerContainer = () => {
  const { data: auth } = useQuery(GET_LOGIN_DATA);
  const userId = auth?.userInfo?.id || "";

  const { data, loading, error } = useQuery(GET_PROJECT, {
    skip: !userId,
    variables: { managerId: userId },
  });

  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;
  const projectList = duplicateResponse(data?.result);

  return (
    <GenericListContainer
      list={projectList}
      structureName={"Project"}
      parentValue={"managerPage"}
      linkFunction={toProjectVersion}
      secondLink={toProjectUnits}
      budgetLink={toBudgetPopUp}
      detailsBtnLabel={"projectVersion"}
    />
  );
};

export default ProjectMangerContainer;
