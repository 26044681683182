import { Spinner } from "reactstrap";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import GenericListContainer from "../GenericList/GenericListContainer";
import { GET_BUDGET_TEMPLATE } from "../GraphQl/query";
import { duplicateResponse } from "../Helpers/functions";

const BudgetContainer = () => {
  const { budgetId } = useParams();
  let missingheader = "Foo";

  const { data, loading, error } = useQuery(GET_BUDGET_TEMPLATE, {
    variables: { projectId: parseInt(budgetId) },
    fetchPolicy: "network-only",
  });
  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;

  const budgetList = duplicateResponse(data.result);

  return (
    <GenericListContainer
      list={budgetList}
      structureName={"Budgets"}
      missingheader={missingheader}
    />
  );
};

export default BudgetContainer;
