import ModalComponent from "./ModalComponent";
import {getTableStructure} from "../Helpers/functions";


const ModalContainer =(props) => {

  
        const {label, structureName, lastIndex, modalState, toggleModalState, values, setValues, isUploadEnabled} = props;
        
        
        const structure = getTableStructure(structureName, props.structure);
        
       
        
        if (structureName !== "Project" && structureName !== "SubCategoryTotalDto") {
            return (
                <ModalComponent toggleModal={toggleModalState}
                                isModalOpen={modalState}
                                label={label}
                                structure={structure}
                                structureName={structureName}
                                lastIndex={lastIndex}
                                values={values}
                                setValues={setValues}
                                isUploadEnabled={isUploadEnabled}
                                canAdd={structureName !== 'ValidationRule'}
                />);

        } else {
            return null
        }
};

export default ModalContainer
