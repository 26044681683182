import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";

import { GET_LOGIN_DATA, GET_STRUCTURE_SUBCATEGORY } from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";

const StructureSubcategory = () => {
  const { structureId } = useParams();

  const {
    loading: authLoading,
    error: authError,
    data: auth,
  } = useQuery(GET_LOGIN_DATA);

  const { data, loading, error } = useQuery(GET_STRUCTURE_SUBCATEGORY, {
    variables: { structureId: parseInt(structureId) },
    fetchPolicy: "network-only",
    skip: !auth, // Prevents running before auth data is available
  });

  if (authLoading || loading)
    return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (authError || error) return <div>{error.message}</div>;

  const structureSubcategory = data.result.map(
    ({ subcategory, model, active, id, numrow }) => ({
      subcategory: subcategory.description,
      subcategory_id: subcategory.id,
      model: model.description,
      model_id: model.id,
      active,
      id,
      numrow,
    })
  );

  const parentValue = data.result.find((item) => item?.structure)?.structure
    ?.description;

  return (
    <GenericListContainer
      list={structureSubcategory}
      structureName={"StructureSubcategory"}
      parentName={"Structure"}
      parentValue={parentValue}
      btnLabel={"Create StructureSubCategory"}
    />
  );
};

export default StructureSubcategory;
