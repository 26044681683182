import { useContext, useEffect } from "react";
import { Spinner } from "reactstrap";
import i18n from "i18next";
import { useQuery, useApolloClient } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";

import {
  GET_PRJ_INFO,
  GET_STRUCTURE_INFO,
  GET_SUBCATEGORY_TOTALS,
  GET_UNITS,
  GET_UNITS_LIST,
  GET_LOGIN_DATA,
} from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import { FINANCIAL } from "../../navigation/Routes";
import { isNumber } from "../Helpers/calculationFunctions";
import { ProjectContext } from "../ProjectProvider";
import {
  APPROVED,
  CONSOLIDATED,
  SNAPSHOTTED,
  SUBMITTED,
  VERIFIED,
  ADMIN,
  MOH,
} from "../Helpers/constants";

const SubcategoryTotals = () => {
  const { setProject } = useContext(ProjectContext);
  const client = useApolloClient();
  const { state } = useLocation();
  const { orgVersionId, structureId: id } = useParams();
  const {
    projectCode,
    instituteDescription,
    projectId,
    projectStatus,
    organizationId,
    coFinancing,
    status,
    isCloned,
    isExternal,
    orgVersionInstCode,
    externalProjectId,
    structureInfo,
    projectYear,
  } = state;

  const organizationVersionId = parseInt(orgVersionId);
  const structureId = parseInt(id);

  const consolidatedStatuses = [
    i18n.t(CONSOLIDATED),
    i18n.t(SNAPSHOTTED),
    i18n.t(SUBMITTED),
    i18n.t(VERIFIED),
    i18n.t(APPROVED),
  ];

  useEffect(
    () => {
      setProject(`${projectCode}\n${instituteDescription}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const prepareTotals = (data) => {
    return data.subcategoryTotals.reduce(function (acc, row) {
      const found = acc.find((a) => a.subcategoryId === row.subcategoryId);
      if (found) {
        if (row.accessor) {
          found[row.accessor] = row.sumTotal;
          found.structureId = row.structureId;
        }
      } else {
        const r = { ...row };
        if (row.accessor) r[r.accessor] = r.sumTotal;
        delete r.accessor;
        delete r.sumTotal;
        acc.push(r);
      }
      return acc;
    }, []);
  };

  const addPercToList = (list, totalCost, eligibleCost) => {
    return list.map((row) => {
      row.total_cost_perc =
        !isNaN(row.total_cost) && totalCost > 0
          ? ((row.total_cost / totalCost) * 100).toFixed(2)
          : null;
      row.eligible_cost_perc =
        !isNaN(row.eligible_cost) && eligibleCost > 0
          ? ((row.eligible_cost / eligibleCost) * 100).toFixed(2)
          : null;
      return row;
    });
  };

  const toFinancial = (modelId, structureId, navigate) => {
    navigate(`${FINANCIAL}/${modelId}/${organizationVersionId}`, {
      state: {
        isExternal,
        status,
        isCloned,
        structureId,
        orgVersionInstCode,
      },
    });
  };

  const filterUnits = (units) => {
    if (units)
      return units.result.filter(
        (unit) => unit.organization.id === organizationId
      );
  };

  const getCoFinancial = (unitLis) => {
    const canBeConsolidated = consolidatedStatuses.includes(status);
    if (unitLis?.length > 0 && !canBeConsolidated)
      return unitLis[0].coFinancing;
    return coFinancing;
  };

  const getTotals = (list, col) => {
    return list
      .map((item) => isNumber(item[col]))
      .reduce((acc, red) => acc + red, 0);
  };

  const filterStatusList = (list, auth) => {
    const userRole = auth ? auth.userInfo.role : "";
    if (
      projectStatus === i18n.t(SUBMITTED) &&
      ![ADMIN, MOH].includes(userRole)
    ) {
      return list.map((item) => ({ ...item, status: undefined }));
    }
    return list;
  };

  const { data: auth, loading: authLoading } = useQuery(GET_LOGIN_DATA);

  const { data: units, loading: unitsLoading } = useQuery(GET_UNITS, {
    variables: { projectId },
    skip: !auth, // Prevents running before auth data is available
  });

  const { data, loading, error } = useQuery(GET_SUBCATEGORY_TOTALS, {
    variables: {
      organizationVersionId,
      structureId: structureId,
    },
    fetchPolicy: "network-only",
    skip: !units, // Prevents running before auth data is available
  });

  if (loading || unitsLoading || authLoading)
    return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;

  const list = prepareTotals(data);
  const total_cost = getTotals(list, "total_cost");
  const eligible_cost = getTotals(list, "eligible_cost");
  const total_current_cost = getTotals(list, "current_total_cost");
  const eligible_current_cost = getTotals(list, "current_eligible_cost");
  const newList = addPercToList(list, total_cost, eligible_cost);
  let filteredStatuslist = filterStatusList(newList, auth);
  const unitList = filterUnits(units);
  const coFinancig = getCoFinancial(unitList);

  const updateCache = () => {
    client.writeQuery({
      query: GET_PRJ_INFO,
      data: {
        year: projectYear,
        projectId,
        externalProjectId,
      },
    });
    client.writeQuery({
      query: GET_UNITS_LIST,
      data: {
        result: unitList,
      },
    });
    client.writeQuery({
      query: GET_STRUCTURE_INFO,
      data: {
        structInfo: structureInfo,
      },
    });
  };

  updateCache();

  return (
    <GenericListContainer
      list={filteredStatuslist}
      isExternal={isExternal}
      structureId={structureId}
      coFinancig={coFinancig}
      structureName={"SubCategoryTotalDto"}
      totalCost={total_cost}
      totalCurrentCost={total_current_cost}
      eligibleCost={eligible_cost}
      eligibleCurrentCost={eligible_current_cost}
      parentValue={projectCode}
      btnLabel={"Create SubCategory"}
      detailsBtnLabel={"Dettaglio"}
      linkFunction={toFinancial}
    />
  );
};

export default SubcategoryTotals;
