import { Route, Routes } from "react-router-dom";

import * as routes from "./Routes";

import Unauthorized from "../components/Unauthorized";
import CategoriesList from "../components/Categories";
import SubCategoriesList from "../components/SubCategory/SubCategory";
import ModelListContainer from "../components/Model/ModelListContainer";
import HomePage from "../components/Home";
import AuthFormContainer from "../components/AuthForm/AuthFormContainer";
import OrganizationList from "../components/Organization/OrganizationList";
import ProjectList from "../components/Project/ProjectList";
import TipologieProgetto from "../components/Structure/TipologieProgetto";
import FinancialContainer from "../components/Financials/FinancialContainer";
import SubcategoryTotals from "../components/Totals/SubcategoryTotals";
import UploadFormContainer from "../components/UploadForm/UploadFormContainer";
import ModelItemsFormContainer from "../components/ModelItemsForm/ModelItemsFormContainer";
import StructureList from "../components/Structure/StructureList";
import StructureSubcategory from "../components/Structure/StructureSubcategory";
import VerificationRuleContainer from "../components/ErrorListContainer/VerificationRuleContainer";
import ErrorListContainer from "../components/ErrorListContainer/ErrorListContainer";
import SSOAUTH from "../components/AuthSSO";
import ProjectMangerContainer from "../components/ProjectManagerPage/ProjectManagerPage";
import BudgetContainer from "../components/Budget/budget";
import ProjectVersionContainer from "../components/ProjectVersion/ProjectVersionContainer";
import OrgVersionContainer from "../components/OrganizationVersionContainer/OrganizationVersionContainer";
import MOHPageContainer from "../components/MOHPage/MOHPage";
import ProjectUnitContainer from "../components/ProjectUnit/ProjectUnit";
import StructureListYearsContainer from "../components/Structure/StructureListYearsContainer";

export const AppRoutes = () => (
  <Routes>
    <Route
      path={`${routes.SUBCATEGORIESLIST}/:id?`}
      element={<SubCategoriesList />}
    />
    <Route path={routes.HOME} element={<HomePage />} />
    <Route path={routes.LOGIN} element={<AuthFormContainer />} />
    <Route path={`${routes.LOGINSSO}/:token`} element={<SSOAUTH />} />
    <Route path={`${routes.LOGINSSO}/:token/:code`} element={<SSOAUTH />} />
    <Route path={routes.ORG} element={<OrganizationList />} />
    <Route path={routes.PRJ} element={<ProjectList />} />
    <Route
      path={`${routes.PRJS}/:instituteCode`}
      element={<TipologieProgetto />}
    />
    <Route
      path={`${routes.PROJECTSBYSTRUCTUREYEAR}/:structureId/:year`}
      element={<ProjectList />}
    />
    <Route
      path={`${routes.PROJECTSBYSTRUCTUREYEAR}/:code`}
      element={<ProjectList />}
    />

    <Route
      path={`${routes.ORGVERSION}/:projectVersionId`}
      element={<OrgVersionContainer />}
    />
    <Route path={routes.MANAGERPAGE} element={<ProjectMangerContainer />} />
    <Route
      path={`${routes.PRJVERSION}/:projectId`}
      element={<ProjectVersionContainer />}
    />
    <Route
      path={`${routes.UPLOAD}/:id/:orgVersionId`}
      element={<UploadFormContainer />}
    />
    <Route path={routes.CATEGORIESLIST} element={<CategoriesList />} />
    <Route path={routes.STRUCTURE} element={<StructureList />} />

    <Route
      path={`${routes.STRUCTUREYEARS}/:structureId`}
      element={<StructureListYearsContainer />}
    />

    <Route
      path={`${routes.STRUCTURESUBCATEGORIESLIST}/:structureId?`}
      element={<StructureSubcategory />}
    />
    <Route path={`${routes.MODELS}/:id`} element={<ModelListContainer />} />
    <Route path={`${routes.MODELS}`} element={<ModelListContainer />} />
    <Route
      path={`${routes.FINANCIAL}/:id/:orgVersionId`}
      element={<FinancialContainer />}
    />
    <Route
      path={`${routes.SUBCATEGORIESTOTALS}/:orgVersionId/:structureId`}
      element={<SubcategoryTotals />}
    />
    <Route
      path={`${routes.ERRORS}/:orgVersionId`}
      element={<ErrorListContainer />}
    />
    <Route path={routes.UNAUTHORIZED} element={<Unauthorized />} />
    <Route
      path={`${routes.ITEM}/:subcategoryId`}
      element={<ModelItemsFormContainer />}
    />
    <Route
      path={`${routes.RULES}/:structureId`}
      element={<VerificationRuleContainer />}
    />
    <Route path={routes.MOHPAGE} element={<MOHPageContainer />} />
    <Route
      path={`${routes.PRJUNITS}/:projectId`}
      element={<ProjectUnitContainer />}
    />
    <Route path={`${routes.BUDGETS}/:budgetId`} element={<BudgetContainer />} />
  </Routes>
);

export default AppRoutes;
