import PropTypes from "prop-types";
import Select from "react-select";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";

import { getOptions } from "../Helpers/functions";

const customCellStyle = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    lineHeight: 1.3,
    color: "black",
  }),
};

const mapOptions = (list) =>
  list.length > 0 && typeof list[0] === "object"
    ? list
    : list.map((items) => ({
        value: items,
        label: items,
      }));

const setDefaultValue = (fields, structureName, list = []) => {
  const defaultValue = list.find(
    (item) => item.label === fields.value || item.value === fields.value
  );
  if (defaultValue) {
    return defaultValue;
  } else
    return structureName === "ReasonCode"
      ? { value: "", label: "" }
      : { value: fields.value, label: fields.value };
};

const RowSelectAutocompleteInput = ({
  field: { ...fields },
  form: { touched, errors, setFieldValue, values },
  labelcols,
  label,
  list,
  formGroupClass,
  onChange,
  defaultValue,
  disabled,
  setParameters,
  parentValue,
  dispatch,
  type,
  structureName,
  ...props
}) => (
  <FormGroup row className={formGroupClass}>
    <Label xs={labelcols} for={fields.name} className="label-color text-left">
      {label}
    </Label>
    <Col xs={12 - labelcols}>
      <Select
        styles={customCellStyle}
        isDisabled={disabled}
        defaultValue={setDefaultValue(fields, structureName, list)}
        options={list ? mapOptions(list) : getOptions(label)}
        onChange={(value) => {
          setFieldValue(fields.name, value.value);
        }}
      />
      <Input hidden invalid={touched[fields.name] && errors[fields.name]} />
      <FormFeedback>{errors[fields.name]}</FormFeedback>
    </Col>
  </FormGroup>
);

RowSelectAutocompleteInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired, // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number, // num colonne per etichetta
  list: PropTypes.oneOfType([
    // lista option: array di stringhe o di oggetti { id, desc }
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        desc: PropTypes.string,
      })
    ),
  ]),
  formGroupClass: PropTypes.string,
  disabled: PropTypes.bool,
};

RowSelectAutocompleteInput.defaultProps = {
  labelcols: 3,
  formGroupClass: "",
  disabled: false,
};

export default RowSelectAutocompleteInput;
