import { useState } from "react";
import { useParams } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import UploadFormComponent from "./UploadForm";
import { useMutation, useQuery } from "@apollo/client";
import { uploadExcel } from "../GraphQl/mutation";
import { Alert, Container, Spinner } from "reactstrap";
import { MODEL_DESCR } from "../GraphQl/query";
import styles from "./Upload.module.css";

const initialValues = {
  file: "",
};

const formSchema = Yup.object().shape({
  file: Yup.string(),
});

const UploadFormContainer = () => {
  const { id, orgVersionId } = useParams();
  const modelId = parseInt(id);
  const { data, error, loading } = useQuery(MODEL_DESCR, {
    variables: { modelId: modelId },
  });
  const [upload] = useMutation(uploadExcel);
  const [isAlertOpen, toggleAlert] = useState(false);
  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;
  const isUploadEnabled = data.models[0] ? data.models[0].uploadEnabled : false;
  if (isUploadEnabled) {
    return (
      <Container fluid>
        <Alert
          color={color}
          isOpen={isAlertOpen}
          toggle={() => toggleAlert(false)}
        >
          {`${message}`}
        </Alert>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setIsLoading(false);
            setSubmitting(false);
            try {
              await upload({
                variables: {
                  modelId: modelId,
                  orgVersionId: orgVersionId,
                  reportInput: { fileDownloadUri: values.file },
                },
              });
              setColor("success");
              setMessage("Caricato");
              toggleAlert(true);
              setTimeout(() => toggleAlert(false), 1200);
            } catch (error) {
              setColor("danger");
              setMessage(error);
              toggleAlert(true);
            } finally {
              setIsLoading(true);
            }
          }}
        >
          {(props) => (
            <UploadFormComponent
              props={props}
              isHiddenLoader={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </Formik>
      </Container>
    );
  } else {
    return (
      <Container>
        <div className={styles.title}>
          <h3>Attenzione:</h3>
          <div className={styles.subtitle}>
            <div>
              <strong>Operazione non consentita</strong>
            </div>
          </div>
        </div>
      </Container>
    );
  }
};

UploadFormContainer.defaultProps = {};

export default UploadFormContainer;
