import { useState } from "react";
import { Formik } from "formik";
import { Alert, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useMutation, useApolloClient } from "@apollo/client";

import AuthFormComponent from "./AuthFormComponent";
import { GET_LOGIN_DATA } from "../GraphQl/query";
import { AUTH } from "../GraphQl/mutation";


import { getRole, redirect } from "../Helpers/routerFunctions";

const initialValues = {
  username: "",
  password: "",
};

const AuthFormContainer = () => {
  const navigate = useNavigate();

  const client = useApolloClient();
  const [isAlertOpen, toggleAlert] = useState(false);
  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [login] = useMutation(AUTH, {
    onCompleted(data) {
      localStorage.setItem("token", data.authenticate.token);
      let role = getRole(data.authenticate);
      client.writeQuery({
        query: GET_LOGIN_DATA,
        data: {
          isLoggedIn: true,
          userInfo: { ...data.authenticate.user, role: role },
        },
      });

      redirect(navigate, role);
    },
  });

  return (
    <Container fluid>
      <Alert
        color={color}
        isOpen={isAlertOpen}
        toggle={() => toggleAlert(false)}
      >
        {`${message}`}
      </Alert>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);
          localStorage.clear();
          try {
            await login({
              variables: {
                username: values.username,
                password: values.password,
              },
            });
          } catch (e) {
            console.error(e);
            setColor("danger");
            setMessage("Errore: username o password non valido");
            toggleAlert(true);
            setTimeout(() => toggleAlert(false), 5000);
          }
        }}
      >
        {(props) => <AuthFormComponent {...props} />}
      </Formik>
    </Container>
  );
};

export default AuthFormContainer;
