import { useEffect, useState } from "react";
import { Spinner, Button } from "reactstrap";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";

import { ProjectVersionModal } from "../Modals/ProjectVersionModal/ProjectVersionModal";
import GenericListContainer from "../GenericList/GenericListContainer";
import { GET_PROJECT_VERSION, FIND_PROJECT } from "../GraphQl/query";
import { duplicateResponse } from "../Helpers/functions";
import { ORGVERSION } from "../../navigation/Routes";
import {
  getDefaultStartDate,
  getDefaultEndDate,
} from "../Modals/ProjectVersionModal/functions";

const toOrgVersion = (projectVersionId, navigate, project) => {
  navigate(`${ORGVERSION}/${projectVersionId}`, {
    state: {
      projectVersionDesc: project.description,
      hasUnits: project.project.structure.hasUnits,
      from: "prjVersion",
    },
  });
};

const ProjectVersionContainer = () => {
  const { projectId } = useParams();
  const { state } = useLocation();
  const projectCode = state?.projectCode;
  const developer = state?.developer;
  const missingheader = state?.missingheader;
  const project = state?.project;

  const { t } = useTranslation();
  // const [modalState, toggleModalState] = useState(false);
  const [updateModalState, toggleUpdateModalState] = useState(false);
  const [values, setValues] = useState();
  const [isUpdate, setIsUpdated] = useState(false);
  const [projectVersionList, setProjectVersionList] = useState([]);
  const [projectVersion, setProjectVersion] = useState({});

  const { data, loading, error } = useQuery(GET_PROJECT_VERSION, {
    variables: { projectId: projectId },
  });

  const { data: dataProject } = useQuery(FIND_PROJECT, {
    variables: { projectId },
  });

  useEffect(() => {
    if (data?.result) {
      setProjectVersionList(duplicateResponse(data?.result));
    }
  }, [data]);

  useEffect(
    () => {
      if (dataProject) {
        const project = dataProject.result ? dataProject.result : {};
        var data = { ...projectVersion };
        data.project = project;
        data.active = true; // Settato Ad Attivo Per Default E Poi Nascosto
        data.startPeriodDate = getDefaultStartDate(data, projectVersionList);
        data.endPeriodDate = getDefaultEndDate(data);
        data.projectId = project.id;
        data.versionType = "";
        data.description = "";
        setProjectVersion(data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataProject]
  );

  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;

  const AddItemComponent = (
    <Button
      onClick={() => {
        setValues(projectVersion);
        setIsUpdated(false);
        toggleUpdateModalState(true);
      }}
    >
      {"Inserimento"}
    </Button>
  );

  const customColumns = [
    {
      accessor: "update",
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <Button
          hidden={!row.original.active}
          onClick={() => {
            setValues(row.original);
            setIsUpdated(true);
            toggleUpdateModalState(true);
          }}
        >
          {t("mod")}
        </Button>
      ),
    },
  ];

  return (
    <>
      {updateModalState && (
        <ProjectVersionModal
          toggleModal={toggleUpdateModalState}
          modalState={updateModalState}
          list={projectVersionList}
          translate={t}
          values={values}
          isUpdate={isUpdate}
        />
      )}

      <GenericListContainer
        list={projectVersionList}
        structureName={"ProjectVersion"}
        parentValue={projectCode}
        linkFunction={toOrgVersion}
        detailsBtnLabel={"orgVersion"}
        developer={developer}
        missingheader={missingheader}
        project={project}
        AddItemComponent={AddItemComponent}
        customColumns={customColumns}
      />
    </>
  );
};

export default ProjectVersionContainer;
