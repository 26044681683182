import {Field} from 'formik';

import {Button, Container, Form, Spinner} from 'reactstrap';
import {FileUpload} from '../FormElements';

import styles from './Upload.module.css';


const UploadFormComponent = ({props,isHiddenLoader}) => {

    return (

        <Container>
            <Form onSubmit={props.handleSubmit}>
                <div className={styles.title}>
                    <h3>Attenzione:</h3>
                    <div className={styles.subtitle}>
                        <div>
                            <strong>Tutti i dati saranno sovrascritti da quelli presenti nel file</strong>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.content}>
                        <Field label="File da caricare" component={FileUpload} prefix="tmp/"
                               onload={value => props.setFieldValue('file', value)}/>
                    </div>
                    <Spinner className={styles.spinner} animation="border" hidden={isHiddenLoader}/>
                    <div className="justify-content-end">
                        <Button hidden={!isHiddenLoader} color="success" type="submit">Carica File</Button>
                    </div>
                </div>
            </Form>
        </Container>

    );
};


export default UploadFormComponent;
