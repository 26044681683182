import { useContext, useCallback, useState } from "react";
import { Button, ButtonGroup, Container, Row, Col, Spinner } from "reactstrap";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import GenericListComponent from "./GenericListComponent";
import {
  APPROVED,
  CONSOLIDATED,
  SUBMITTED,
  VERIFIED,
  ROLES_TO_SEE_STATUS,
  ROLES_TO_SEE_STATUS_RESTRICTED,
} from "../Helpers/constants";
import ModalContainer from "../Modal/ModalContainer";
import { getStructureQuery } from "../Helpers/mutation";
import {
  checkStatus,
  getTableStructure,
  capitalize,
} from "../Helpers/functions";
import { ProjectContext } from "../ProjectProvider";

import ConfirmationModal from "../ConfirmationModal";
import {
  CLONEORGANIZATION,
  CONSOLIDATE_ORG_VERSION,
  DOIMPORT,
  REQUESTFINANCIALSV2,
  SETFINANTIALSTATUS,
  SNAPSHOT_ORG_VERSION,
  UPDATESTATUS,
} from "../GraphQl/mutation";
import {
  GET_LOGIN_DATA,
  GET_PRJ_INFO,
  GET_STRUCTURE_INFO,
} from "../GraphQl/query";

import BudgetModal from "../Modals/Budgets/BudgetModal";
import SubmitModal from "../SubmitModal/SubmitModal";
import ReasonCodeModal from "../Modals/ReasonCodeModal/ReasonCodeModal";
import CommentModal from "../Modals/CommentModal/CommentModal";
import ManageUserModal from "../Modals/ManageUserModal/ManageUserModal";
import { ITEM, MANAGERPAGE, ORGVERSION } from "../../navigation/Routes";
import AlertComponent from "../ModelItemsForm/AlertComponent";
import {
  applyTranslate,
  changeStatus,
  getFinancialRequest,
  getNonStatuses,
  getOnCompleteOptions,
  handleSelectAll,
} from "../Helpers/GenericListHelpers/genericListFunctions";

import ProjectUnitModal from "../Modals/ProjectUnitModal/ProjectUnitModal";
import { prepare } from "../Helpers/GenericListHelpers/prepareColumn";

import styles from "./GenericList.module.css";

const GenericListContainer = ({
  list,
  structureName,
  secondLink,
  budgetLink,
  secondDetailsBtn,
  btnLabel,
  detailsBtnLabel,
  linkFunction,
  headers,
  doSubmit,
  parentName,
  parentValue,
  totalCost,
  totalCurrentCost,
  eligibleCost,
  eligibleCurrentCost,
  isUploadEnabled,
  status,
  inmodal,
  instCode,
  isCloned,
  coFinancig,
  structureId,
  isManagerHasUnit,
  missingheader,
  code,
  prj_desc,
  isExternal,
  AddItemComponent,
  customColumns,
}) => {
  const { project } = useContext(ProjectContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id, orgVersionId, projectVersionId } = useParams();
  const { state, pathname } = useLocation();
  const orgVersionInstCode = state?.orgVersionInstCode;
  const [modalState, toggleModalState] = useState(false);

  const [budgetState, toggleBudgetState] = useState(false);

  const [confModalState, toggleConfModalState] = useState(false);
  const [submitModalState, toggleSubmitModalState] = useState(false);
  const [commentModalState, toggleCommentModalState] = useState(false);
  const [manageUserModalState, toggleManageUserModalState] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [, setFiltered] = useState([]);
  const [selectedRows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [values, setValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [isAlertOpen, toggleAlert] = useState(false);
  const [alertMessage, setMessage] = useState("");

  // const [doUpdate] = useMutation(getUpdateMutation(structureName));
  const [doApprove] = useMutation(SETFINANTIALSTATUS, {
    onCompleted(data) {
      setRows([]);
      setValues(null);
      setSpinnerStatus(false);
    },
  });
  const [consolidate] = useMutation(CONSOLIDATE_ORG_VERSION, {
    onCompleted(data) {
      if (data.result) {
        setSpinnerStatus(false);
        navigate(MANAGERPAGE, { refetch: true });
      }
    },
  });
  const [snapshot] = useMutation(SNAPSHOT_ORG_VERSION, {
    onCompleted(data) {
      if (data.result) {
        setSpinnerStatus(false);
        setIsLoading(false);
        navigate(`${ORGVERSION}/${projectVersionId}`);
      }
    },
  });
  const [doSubmitMutation] = useMutation(UPDATESTATUS);
  const [doCloneOrg] = useMutation(CLONEORGANIZATION, {
    onCompleted() {
      setIsLoading(false);
    },
  });
  const [doCloneOrgVersionManager] = useMutation(CLONEORGANIZATION, {
    onCompleted() {
      navigate(`${ORGVERSION}/${projectVersionId}`);
    },
  });

  const [doReopenOrg] = useMutation(UPDATESTATUS, {
    onCompleted() {
      navigate(`${ORGVERSION}/${projectVersionId}`);
    },
  });
  const { data: auth } = useQuery(GET_LOGIN_DATA);
  const userRole = auth ? auth.userInfo.role : "";
  const { data: projectInfo } = useQuery(GET_PRJ_INFO);
  const { data: structInfo } = useQuery(GET_STRUCTURE_INFO);
  const { financialsApi } = structInfo
    ? structInfo.structInfo
    : { financialsApi: null };
  const { data: userInfo } = useQuery(GET_LOGIN_DATA);
  const projectYear = projectInfo ? projectInfo.year - 1 : 0;
  const [doImport, loadingImport] = useMutation(
    DOIMPORT,
    getOnCompleteOptions(navigate)
  );
  const [requestFinancials, loadingFinancials] = useMutation(
    REQUESTFINANCIALSV2,
    getOnCompleteOptions(navigate)
  );

  const { data, loading, error } = useQuery(getStructureQuery(structureName), {
    variables: { id: id, structureName: structureName },
  });

  const handleFilteredDataChange = useCallback((rows) => {
    setFilteredRows(rows);
  }, []);

  const tableStruct = getTableStructure(structureName, data);

  applyTranslate(tableStruct, t, list, structureName);

  const headersName = headers ? headers : [];
  const isProjectManagerPage = pathname.includes(MANAGERPAGE);
  const isCommentBtnDisabled = ["Approvato", "Verificato"].includes(status);

  if (loading || loadingImport.loading || loadingFinancials.loading)
    return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;
  const lastIndex = list ? list.length : 0;
  if (typeof isUploadEnabled === "undefined") isUploadEnabled = true;

  // PM 20241102 campi necessari per verifiche per riapertura / clonazione OrganizationVersion
  let isConsolidated;
  let isVerified;
  let verifiedDate;
  if (structureName === "OrganizationVersion") {
    const consolidation = list.filter(
      (l) => l.status === t(CONSOLIDATED) || l.status === t(SUBMITTED)
    );
    const verification = list.filter((l) => l.status === t(VERIFIED));
    // consolidato e verificato si succedono e c'è sempre un consolidato prima del relativo verificato
    isConsolidated =
      consolidation.length > 0 && consolidation.length > verification.length;
    isVerified =
      verification.length > 0 && verification.length >= consolidation.length;
    verifiedDate =
      verification.length > 0 ? verification[0].lastModifiedDate : null;
  }

  let progetto_in_header;
  let istituto_in_header;

  if (missingheader != undefined && missingheader.length > 0) {
    progetto_in_header =
      missingheader.indexOf("\n") > 0
        ? missingheader.substring(0, missingheader.indexOf("\n"))
        : missingheader;
    istituto_in_header =
      missingheader.indexOf("\n") > 0
        ? missingheader.substring(1 + missingheader.indexOf("\n"))
        : "";
  } else if (project != undefined && project.length > 0) {
    progetto_in_header =
      project.indexOf("\n") > 0
        ? project.substring(0, project.indexOf("\n"))
        : project;
    istituto_in_header =
      project.indexOf("\n") > 0
        ? project.substring(1 + project.indexOf("\n"))
        : "";
  }
  const columnsToDisplay = prepare(
    setValues,
    toggleBudgetState,
    toggleModalState,
    doApprove,
    structureName,
    tableStruct,
    detailsBtnLabel,
    linkFunction,
    secondLink,
    secondDetailsBtn,
    budgetLink,
    headersName,
    parentName,
    toggleConfModalState,
    isUploadEnabled,
    toggleSubmitModalState,
    status,
    setRows,
    selectedRows,
    toggleCommentModalState,
    userRole,
    spinnerStatus,
    t,
    doSubmitMutation,
    doCloneOrg,
    instCode,
    setIsLoading,
    navigate,
    isProjectManagerPage,
    doCloneOrgVersionManager,
    doReopenOrg,
    isConsolidated,
    isVerified,
    verifiedDate,
    toggleManageUserModalState
  ).filter((c) => c.show || c.show === undefined);

  return (
    <Container fluid>
      {isAlertOpen && (
        <AlertComponent
          color={"danger"}
          isAlertOpen={isAlertOpen}
          message={alertMessage}
          toggleAlert={toggleAlert}
        />
      )}

      {prj_desc != undefined && (
        <Row>
          <Col xs={6}>
            <div className={styles.head_k_b}>&nbsp;</div>
          </Col>
          <Col xs={1}>
            <div className={styles.head_k_b}>Tipologia:</div>
          </Col>
          <Col xs={3}>
            <div className={styles.head_v}>{prj_desc}</div>
          </Col>
          <Col></Col>
        </Row>
      )}

      {missingheader != undefined && missingheader.length > 0 && (
        <Row>
          <Col xs={1}>
            <div className={styles.head_k_b}>&nbsp;</div>
          </Col>
          <Col xs={3}>
            <div className={styles.head_v_b}>{progetto_in_header}</div>
          </Col>
          <Col xs={1}>
            <div className={styles.head_k_b}>Istituto:</div>
          </Col>
          <Col xs={6}>
            <div className={styles.head_v_b}>
              {capitalize(istituto_in_header)} &nbsp; {"("}{" "}
              {parentValue ? t(parentValue) : t(structureName)} {")"}
            </div>
          </Col>

          <Col></Col>
        </Row>
      )}

      {missingheader != undefined && missingheader.length > 0 && (
        <Row>
          <Col xs={1}>
            <div>&nbsp;</div>
          </Col>
        </Row>
      )}

      {parentValue !== "managerPage" && project.length > 0 && (
        <Row>
          <Col xs={1}>
            <div className={styles.head_k_b}>&nbsp;</div>
          </Col>
          <Col xs={2}>
            <div className={styles.head_v_b}>{progetto_in_header}</div>
          </Col>
          <Col xs={1}>
            <div className={styles.head_k_b}>Istituto:</div>
          </Col>
          <Col xs={4}>
            <div className={styles.head_v_b}>
              {capitalize(istituto_in_header)} &nbsp; {"("}{" "}
              {parentValue ? t(parentValue) : t(structureName)} {")"}
            </div>
          </Col>

          {coFinancig > 0 && (
            <>
              <Col xs={2}>
                <div className={styles.head_k}>Finanziamento:</div>
              </Col>
              <Col xs={2}>
                <div className={styles.head_v}>
                  <NumericFormat
                    thousandSeparator={"."}
                    prefix={"€ "}
                    decimalSeparator={","}
                    value={parseFloat(coFinancig)}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    disabled
                  />
                </div>
              </Col>
            </>
          )}

          <Col></Col>
        </Row>
      )}

      {parentValue !== "managerPage" && project.length > 0 && (
        <Row>
          <Col xs={1}>
            <div>&nbsp;</div>
          </Col>
          <Col></Col>
        </Row>
      )}

      {totalCost > 0 && (
        <Row>
          <Col xs={2}>
            <div>&nbsp;</div>
          </Col>
          <Col xs={2}>
            <div className={styles.head_k}>Costo Totale:</div>
          </Col>
          <Col xs={2}>
            <div className={styles.head_v}>
              <NumericFormat
                thousandSeparator={"."}
                prefix={"€ "}
                decimalSeparator={","}
                value={parseFloat(totalCost)}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                disabled
              />
            </div>
          </Col>

          <Col xs={2}>
            <div className={styles.head_k}>Costo Eleggibile:</div>
          </Col>
          <Col xs={2}>
            <div className={styles.head_v}>
              <NumericFormat
                thousandSeparator={"."}
                prefix={"€ "}
                decimalSeparator={","}
                value={parseFloat(eligibleCost)}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                disabled
              />
            </div>
          </Col>

          <Col></Col>
        </Row>
      )}

      {eligibleCost > 0 && (
        <Row>
          <Col xs={2}>
            <div>&nbsp;</div>
          </Col>

          <Col xs={2}>
            <div className={styles.head_k}>Corrente:</div>
          </Col>
          <Col xs={2}>
            <div className={styles.head_v}>
              <NumericFormat
                thousandSeparator={"."}
                prefix={"€ "}
                decimalSeparator={","}
                value={parseFloat(totalCurrentCost)}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                disabled
              />
            </div>
          </Col>

          <Col xs={2}>
            <div className={styles.head_k}>Corrente:</div>
          </Col>
          <Col xs={2}>
            <div className={styles.head_v}>
              <NumericFormat
                thousandSeparator={"."}
                prefix={"€ "}
                decimalSeparator={","}
                value={parseFloat(eligibleCurrentCost)}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                disabled
              />
            </div>
          </Col>

          <Col></Col>
        </Row>
      )}

      {structureName === "Financial" &&
        ROLES_TO_SEE_STATUS.includes(userRole) && (
          <Row>
            <Col xs={1}>
              <div>&nbsp;</div>
            </Col>
          </Row>
        )}

      <Row>
        <Col xs="2" hidden={checkStatus(status)}>
          {structureName !== "Structure" &&
            structureName !== "ProjectVersion" &&
            structureName !== "ProjectUnits" &&
            structureName !== "OrganizationVersion" &&
            structureName !== "StructureMOHStructure" && (
              <div hidden={structureName === "Model"}>
                <ModalContainer
                  doSubmit={doSubmit}
                  label={btnLabel}
                  structure={data}
                  lastIndex={lastIndex}
                  structureName={structureName}
                  modalState={modalState}
                  values={values}
                  setValues={setValues}
                  toggleModalState={toggleModalState}
                  isUploadEnabled={isUploadEnabled}
                  doImport={doImport}
                />
              </div>
            )}
          {structureName === "Model" && (
            <Button onClick={() => navigate(`${ITEM}/${id}`)}>
              Inserimento
            </Button>
          )}

          {structureName === "ProjectVersion" && AddItemComponent}

          {structureName === "ProjectUnits" && (
            <ProjectUnitModal
              toggleModal={toggleModalState}
              modalState={modalState}
              values={values}
              list={list}
              translate={t}
              setValues={setValues}
            />
          )}

          {!isUploadEnabled && structureName === "Financial" && !isExternal && (
            <Button
              onClick={async () => {
                try {
                  await getFinancialRequest(
                    requestFinancials,
                    id,
                    orgVersionId,
                    userInfo,
                    projectInfo,
                    financialsApi,
                    doImport,
                    projectYear,
                    orgVersionInstCode
                  );
                } catch (e) {
                  console.log("Error", e);
                }
              }}
            >
              Importa
            </Button>
          )}
          {structureName === "OrganizationVersion" && isManagerHasUnit && (
            <Button
              disabled={isLoading}
              color="danger"
              onClick={async () => {
                try {
                  setIsLoading(true);
                  await consolidate({
                    variables: {
                      projectVersionId: projectVersionId,
                    },
                  });
                } catch (e) {
                  setIsLoading(false);
                  toggleAlert(true);
                  setMessage(t("GENERIC_ERROR")); // needs to use apollo error link
                }
              }}
            >
              {t("cons")}
            </Button>
          )}
        </Col>
        <Col xs="2">
          {structureName === "OrganizationVersion" && isManagerHasUnit && (
            <Button
              disabled={isLoading}
              color="warning"
              onClick={async () => {
                try {
                  setIsLoading(true);
                  await snapshot({
                    variables: {
                      projectVersionId: projectVersionId,
                    },
                  });
                } catch (e) {
                  setIsLoading(false);
                  toggleAlert(true);
                  setMessage(t("GENERIC_ERROR")); // needs to use apollo error link
                }
              }}
            >
              {t("snapshot")}
            </Button>
          )}
        </Col>

        {structureName === "Financial" &&
          ROLES_TO_SEE_STATUS_RESTRICTED.includes(userRole) && (
            <Col>
              <Button
                disabled={!selectedRows.length || isCommentBtnDisabled}
                className={styles.circleBtn}
                size="lg"
                onClick={() => {
                  setValues({
                    rowId: selectedRows,
                    modelId: id,
                    orgVersionId: orgVersionId,
                  });
                  toggleCommentModalState(true);
                }}
                title={t("changeSelectedStatus")}
              />{" "}
              <Button
                size="lg"
                color="success"
                className={styles.circleBtn}
                disabled={selectedRows.length > 0 || isCommentBtnDisabled}
                onClick={() =>
                  changeStatus(
                    setSpinnerStatus,
                    doApprove,
                    getNonStatuses(list),
                    id,
                    orgVersionId,
                    APPROVED
                  )
                }
                title={t("approveAll")}
              ></Button>{" "}
              <ButtonGroup>
                <Button
                  size="sm"
                  disabled={isCommentBtnDisabled}
                  onClick={() =>
                    handleSelectAll(setRows, selectedRows, filteredRows)
                  }
                >
                  {selectedRows.length ? t("deselectAll") : t("selectAll")}
                </Button>
              </ButtonGroup>
            </Col>
          )}
      </Row>
      <Row md={12}>
        <Col>
          <GenericListComponent
            columns={columnsToDisplay}
            customColumns={customColumns}
            data={list}
            setFiltered={setFiltered}
            inmodal={inmodal}
            loading={isLoading}
            code={code}
            retainFilters={structureName !== "Financial"}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </Col>
      </Row>

      <Col hidden>
        <ConfirmationModal
          isModalOpen={confModalState}
          toggleModal={toggleConfModalState}
          structureName={structureName}
          values={values}
          setValues={setValues}
          doSubmitMutation={doSubmitMutation}
          setIsLoading={setIsLoading}
          isStatusLoading={isLoading}
        />
      </Col>
      {submitModalState && (
        <Col hidden>
          <SubmitModal
            isModalOpen={submitModalState}
            toggleModal={toggleSubmitModalState}
            values={values}
          />
        </Col>
      )}
      {structureName === "Financial" && (
        <Col>
          <ReasonCodeModal
            readOnly={false}
            isModalOpen={commentModalState}
            toggleModal={toggleCommentModalState}
            rowValues={values}
            changeStatus={changeStatus}
            doApprove={doApprove}
            translate={t}
            userRole={userRole}
            nestedModal={nestedModal}
            setNestedModal={setNestedModal}
            closeAll={closeAll}
            setCloseAll={setCloseAll}
            setSpinnerStatus={setSpinnerStatus}
            isCloned={isCloned}
            status={status}
          />
        </Col>
      )}
      {structureName === "SubCategoryTotalDto" && commentModalState && (
        <Col hidden>
          <CommentModal
            isModalOpen={commentModalState}
            toggleModal={toggleCommentModalState}
            rowValues={values}
            translate={t}
            values={values}
            userRole={userRole}
          />
        </Col>
      )}
      {structureName === "ProjectUnits" && manageUserModalState && (
        <Col hidden>
          <ManageUserModal
            instituteCode={manageUserModalState}
            toggleModal={toggleManageUserModalState}
            rowValues={values}
            translate={t}
            values={values}
            userRole={userRole}
          />
        </Col>
      )}

      {budgetState && (
        <Col hidden>
          <BudgetModal
            toggleBudgetState={toggleBudgetState}
            projectId={budgetState}
          />
        </Col>
      )}
    </Container>
  );
};

export default GenericListContainer;
