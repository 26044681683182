import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMutation, useQuery } from "@apollo/client";

import { GET_BUDGET_TEMPLATE_S } from "../../GraphQl/query";
import { UPDATE_PROJECT_BUDGETS } from "../../GraphQl/mutation";

const BudgetModal = ({ toggleBudgetState, projectId }) => {
  const [budgets, setBudgets] = useState([]);
  const [pid, setPid] = useState(0);

  const { loading, data, error } = useQuery(GET_BUDGET_TEMPLATE_S, {
    variables: { projectId },
    fetchPolicy: "no-cache",
  });

  const [saveBudgets, { loading: saveLoading, error: saveError }] = useMutation(
    UPDATE_PROJECT_BUDGETS
  );

  useEffect(() => {
    if (data && data.projectBudgets) {
      const t_budgets = data.projectBudgets.map((item) => ({
        projectId: item.project.id,
        model_desc: item.model.subcategory.description,
        modelId: item.model.id,
        budget: item.budget ? item.budget : 0,
      }));

      t_budgets.sort((a, b) => {
        if (a.model_desc < b.model_desc) return -1;
        if (a.model_desc > b.model_desc) return 1;
        return 0;
      });

      setBudgets(t_budgets);
      setPid(data.projectBudgets[0].project.id);
    }
  }, [data]);

  // Handle loading and error states (add appropriate UI elements)
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  function transformCurrencyString(currencyString) {
    // "€ 5.531,22" -> 5531.22

    // Remove the currency symbol (€) and spaces
    let cleanedString = currencyString.replace(/[^\d,.-]/g, "");

    // Replace the thousands separator (.) with an empty string
    cleanedString = cleanedString.replace(/\./g, "");

    // Replace the decimal separator (,) with a dot (.)
    cleanedString = cleanedString.replace(",", ".");

    // Convert to float
    try {
      return parseFloat(cleanedString);
    } catch (error) {
      return null;
    }
  }

  const handleChange = (event, index) => {
    const updatedBudgets = [...budgets];

    let value = event.target.value;

    if (value === "") value = "0";

    updatedBudgets[index].budget = transformCurrencyString(value);
    setBudgets(updatedBudgets);
  };

  const close = () => {
    toggleBudgetState(false);
  };

  const handleSave = async () => {
    try {
      await saveBudgets({
        variables: {
          projectId: pid,
          projectBudgetInputList: budgets.map((item) => {
            return {
              projectId: item.projectId,
              modelId: item.modelId,
              budget: item.budget,
            };
          }),
        },
      });

      toggleBudgetState(false);
    } catch (err) {
      console.error("Error saving budgets:", err);
    }
  };

  let code;
  let description;
  if (data && data.projectBudgets && data.projectBudgets.length > 0) {
    code = data.projectBudgets[0].project.code;
    description = data.projectBudgets[0].project.title;
  }

  return (
    <div>
      <Modal
        size="medium"
        isOpen={true}
        toggle={() => toggleBudgetState(false)}
      >
        {data && data.projectBudgets ? (
          <>
            <ModalHeader>
              <strong>
                {"Budget Per Progetto:"} {code}
              </strong>
            </ModalHeader>

            <ModalHeader>{description}</ModalHeader>

            <ModalBody>
              {budgets.map((item, index) => (
                <div key={index}>
                  <label className="pt-2">
                    <strong>{item.model_desc}</strong>
                  </label>

                  <NumericFormat
                    className="form-control"
                    prefix={"€ "}
                    thousandSeparator={"."} // Optional: Add comma separators
                    decimalSeparator={","}
                    decimalScale={2} // Enforces two decimal places
                    fixedDecimalScale={true} // Ensures exactly two decimal places
                    placeholder="0.00"
                    value={item.budget}
                    pattern="___.___" // Optional: Display pattern for user guidance
                    required
                    onChange={(event) => handleChange(event, index)}
                  />
                  <br />
                </div>
              ))}
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleSave} disabled={saveLoading}>
                {saveLoading ? "Saving..." : "Salva Budget"}
              </Button>
              <Button onClick={close}>{"Annulla"}</Button>
            </ModalFooter>

            {saveError && (
              <ModalFooter>
                <div>
                  <p className="pt-2">
                    <strong>{saveError.message}</strong>
                  </p>
                </div>
              </ModalFooter>
            )}
          </>
        ) : (
          <p>No budget data available</p>
        )}
      </Modal>
    </div>
  );
};

export default BudgetModal;
