import { useEffect, useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Row, Col, Spinner, Table } from "reactstrap";
import { hash } from "ohash";

import {
  defaultFilterFunction,
  statusFilterFunction,
  booleanFilterFunction,
  currencyFilterFunction,
  booleanDefaultFilterFunction,
} from "../Helpers/filters";
import { TABLE_PAGE_SIZE } from "../Helpers/constants";
import { GenericTablePagination } from "./GenericTablePagination";
import { GenericTableHeader } from "./GenericTableHeader";
import { GenericTableBody } from "./GenericTableBody";

import styles from "../Styles/List.module.css";

function get_hash(columns) {
  let selected_property = "accessor";
  let selected_values = [];

  for (const obj of columns) {
    selected_values.push(obj[selected_property]);
  }

  return hash(columns);
}

const GenericListComponent = ({
  data,
  columns,
  setFiltered,
  inmodal,
  loading,
  code,
  retainFilters,
  onFilteredDataChange,
  customColumns = [],
}) => {
  let tmpfilters = JSON.parse(localStorage.getItem(get_hash(columns))) || [];

  if (!retainFilters) {
    tmpfilters = [];
  }

  if (code !== undefined) {
    tmpfilters.push({ id: "code", value: code });
  }

  const [, setStoreData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [columnSizing, setColumnSizing] = useState({});
  const [filters, setFilters] = useState(tmpfilters);

  useEffect(
    () => {
      const fetchData = async () => {
        setStoreData(data);
      };

      fetchData()
        .then((result) => {})
        .catch((error) => {
          console.error(error.message);
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      let key = get_hash(columns);

      localStorage.setItem(key, JSON.stringify(filters));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  if (code == undefined) code = "";

  const columnHelper = createColumnHelper();

  const columnsToDisplay = [...columns, ...customColumns].map((column) =>
    columnHelper.accessor(column.accessor, {
      cell: column.Cell,
      accessorKey: column.accessor,
      id: column.id ?? column.accessor,
      header: column.Header,
      headerStyle: column.headerStyle,
      style: column.style,
      size: column.size,
      filterVariant: column.filterVariant,
      filterFn: column.filterFn ?? "defaultFilter",
      enableColumnFilter: column.enableColumnFilter,
    })
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: TABLE_PAGE_SIZE,
  });

  const table = useReactTable({
    columns: columnsToDisplay,
    data,
    state: {
      pagination,
      sorting,
      columnSizing,
      columnFilters: filters,
    },
    filterFns: {
      statusFilterFunction,
      booleanFilterFunction,
      currencyFilterFunction,
      booleanDefaultFilterFunction,
      defaultFilter: defaultFilterFunction,
    },
    columnResizeDirection: "ltr",
    enableMultiSort: true,
    enableSortingRemoval: false,
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnSizingChange: setColumnSizing,
    columnResizeMode: "onChange",
  });

  const columnSizingInfo = table.getState().columnSizingInfo;
  const tableColumnSizing = table.getState().columnSizing;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [columnSizingInfo, tableColumnSizing]);

  const tableRowsLength = table.getFilteredRowModel().rows.length;

  useEffect(
    () => {
      const filteredRows = table
        .getFilteredRowModel()
        .rows.map((row) => row.original);
      onFilteredDataChange(filteredRows);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableRowsLength, onFilteredDataChange]
  );

  const hasRows = table.getRowModel().rows.length > 0;

  const MyLoader = () =>
    loading ? (
      <div className="-loading -active">
        <div className="-loading-inner">
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      </div>
    ) : null;

  return (
    <div>
      <Row className={inmodal ? styles.tableInModal : styles.tablePadding}>
        <Col
          md={12}
          className={`p-2 block max-w-full overflow-x-scroll overflow-y-hidden ${styles.table}`}
        >
          <div
            className={styles.tableContainer}
            style={{ overflowX: "auto", maxWidth: "100%" }}
          >
            {loading ? (
              <MyLoader />
            ) : (
              <Table
                border="1"
                style={{
                  ...columnSizeVars,
                  width: "100%",
                  textAlign: "left",
                  tableLayout: "fixed",
                }}
              >
                <GenericTableHeader
                  headerGropus={table.getHeaderGroups()}
                  flexRender={flexRender}
                  styles={styles}
                  columnSizingInfo={table.getState().columnSizingInfo}
                />
                <GenericTableBody
                  rows={table.getRowModel().rows}
                  flexRender={flexRender}
                  styles={styles}
                />
              </Table>
            )}
            {!hasRows && (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                Nessun dato presente
              </p>
            )}
          </div>
          <GenericTablePagination table={table} />
        </Col>
      </Row>
    </div>
  );
};

export default GenericListComponent;
