import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { GETSUBCATEGORYCOMMENT } from "../../GraphQl/query";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  CREATESUBCATEGORYCOMMENT,
  UPDATESUBCATEGORYCOMMENT,
} from "../../GraphQl/mutation";
import { MOH, ADMIN } from "../../Helpers/constants";

// eliminato MANAGER rispetto a quello globale
const ROLES_TO_SEE_STATUS = [MOH, ADMIN];

const CommentModal = ({
  toggleModal,
  isModalOpen,
  translate,
  values,
  userRole,
}) => {
  const [comment, setComment] = useState(null);
  const [isUpdate, setIsUpdateMutationType] = useState(false);
  const { orgVersionId } = useParams();
  const { loading, data, error } = useQuery(GETSUBCATEGORYCOMMENT, {
    variables: {
      organizationVersion: parseInt(orgVersionId),
    },
  });

  React.useEffect(() => {
    if (data && values) {
      const comment = data.result.find(
        (item) =>
          item.model.id === values.modelId &&
          item.organizationVersion.id === parseInt(orgVersionId)
      );
      setIsUpdateMutationType(!!comment);
      setComment(comment ? comment : {});
    } else if (loading || error) setComment({});
  }, [data, error, loading, orgVersionId, values]);

  const handleChange = (e) => {
    setComment((prevComment) => ({
      ...prevComment,
      comment: e.target.value,
    }));
  };

  const getMutation = () => {
    return isUpdate ? UPDATESUBCATEGORYCOMMENT : CREATESUBCATEGORYCOMMENT;
  };

  const [saveComment] = useMutation(getMutation(), {
    onCompleted(data) {
      console.log(data);
    },
  });

  return (
    <div>
      <Button color="danger" onClick={() => toggleModal(false)}>
        {"Add Comment"}
      </Button>
      <Modal isOpen={isModalOpen} toggle={() => toggleModal(false)}>
        <ModalHeader>{translate("leaveComment")}</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            disabled={!ROLES_TO_SEE_STATUS.includes(userRole)}
            defaultValue={comment ? comment.comment : ""}
            onBlur={handleChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!ROLES_TO_SEE_STATUS.includes(userRole)}
            onClick={() => {
              saveComment({
                variables: {
                  commentId: comment?.id,
                  input: {
                    comment: comment.comment ? comment.comment : "",
                    modelId: values.modelId,
                    organizationVersionId: orgVersionId,
                  },
                },
                update: (proxy, { data: { result } }) => {
                  const data = proxy.readQuery({
                    query: GETSUBCATEGORYCOMMENT,
                  });
                  proxy.writeQuery({
                    query: GETSUBCATEGORYCOMMENT,
                    data: {
                      ...data,
                      result: isUpdate
                        ? data?.result
                        : [...(data?.result || []), result],
                    },
                  });
                },
              });
              toggleModal(false);
            }}
          >
            {translate("submitComment")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default CommentModal;
