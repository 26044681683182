import { Spinner } from "reactstrap";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { RULES } from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import { duplicateResponse } from "../Helpers/functions";

const VerificationRuleContainer = () => {
  const { structureId } = useParams();

  const { loading, error, data } = useQuery(RULES, {
    variables: { structureId: parseInt(structureId) },
  });
  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return <div>{error.message}</div>;
  const errorList = duplicateResponse(data.result);
  return (
    <GenericListContainer
      list={errorList}
      structureName={"ValidationRule"}
      parentValue={"validationRuleList"}
    />
  );
};

export default VerificationRuleContainer;
