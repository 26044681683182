import { Input } from "reactstrap";

import styles from "../Styles/List.module.css";

export const GenericTablePagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;
  const totalPages = table.getPageCount();

  const handlePageChange = (e) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;
    table.setPageIndex(page);
  };

  const handlePageSizeChange = (e) => {
    table.setPageSize(Number(e.target.value));
  };

  return (
    <div className={styles.pagination}>
      {/* Previous Button */}
      <button
        className={styles.previous}
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
        aria-label="Go to previous page"
      >
        Precedente
      </button>

      {/* Center Section */}
      <div className={styles.center}>
        {/* Page Info */}
        <div className={styles.pageInfo}>
          <span>Pagina</span>
          <Input
            className={`mx-2 ${styles.input}`}
            type="number"
            min="1"
            max={totalPages}
            value={pageIndex + 1}
            onChange={handlePageChange}
            aria-label="Current page number"
          />
          <span>di {totalPages}</span>
        </div>

        {/* Page Size Selector */}
        <div className={styles.pageSizeOption}>
          <Input
            className={`mx-2 ${styles.input}`}
            type="select"
            value={pageSize}
            onChange={handlePageSizeChange}
            aria-label="Select number of rows per page"
          >
            {[5, 10, 20, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size} righe
              </option>
            ))}
          </Input>
        </div>
      </div>

      {/* Next Button */}
      <button
        className={styles.next}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
        aria-label="Go to next page"
      >
        Successiva
      </button>
    </div>
  );
};
