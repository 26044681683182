import { useEffect, useCallback } from "react";
import { Spinner, Container } from "reactstrap";
import { useLocation, useNavigate } from "react-router";
import { useMutation, useApolloClient } from "@apollo/client";

import { AUTHSSO } from "../GraphQl/mutation";
import { GET_LOGIN_DATA, CODEDETAILS } from "../GraphQl/query";
import { LOGIN } from "../../navigation/Routes";

import { getRole, redirect } from "../Helpers/routerFunctions";

// development auth bypass
const env = process.env.NODE_ENV || "development";
const devToken =
  env === "development" ? process.env.REACT_APP_TOKEN : undefined;
// end development auth bypass

const SSOAUTH = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location?.pathname.split("/");
  const token = pathParts[2];
  let code = pathParts[3];
  const client = useApolloClient();
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("token");

  const [ssoAuth] = useMutation(AUTHSSO, {
    onCompleted(data) {
      localStorage.setItem("token", data.authenticateSSO.token);
      let role = getRole(data.authenticateSSO);

      client.writeQuery({
        query: GET_LOGIN_DATA,
        data: {
          isLoggedIn: true,
          userInfo: { ...data.authenticateSSO.user, role: role },
        },
      });

      client
        .query({
          query: CODEDETAILS,
          variables: {
            code: code ? code : "undefined",
          },
        })
        .then((result) => {
          let code_details = {
            code: code,
            id: undefined,
            year: undefined,
          };

          let codedetailsdata = result.data;

          if (
            codedetailsdata !== undefined &&
            Array.isArray(codedetailsdata.result) &&
            codedetailsdata.result.length === 1
          ) {
            code_details.id = codedetailsdata.result[0].structure.id;
            code_details.year = codedetailsdata.result[0].year;
          } else {
            code_details = undefined;
          }
          redirect(
            navigate,
            role,
            data.authenticateSSO.user.instituteCode,
            code_details
          );
        })
        .catch((error) => {
          console.error("CODEDETAILS ERROR: ", error);
        });
    },
  });

  const callAuthSSO = useCallback(
    async () => {
      localStorage.clear();
      try {
        await client.cache.reset();

        let accessToken;
        if (devToken) accessToken = devToken;
        else accessToken = token ? token : myParam;
        await ssoAuth({ variables: { accessToken } });
      } catch (error) {
        console.log("call AuthSSO error: ", error);
        navigate(LOGIN);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, client, myParam, ssoAuth]
  );

  useEffect(() => {
    async function executeAuthSSO() {
      await callAuthSSO();
    }

    executeAuthSSO();
  }, [callAuthSSO]);

  return (
    <Container>
      {true && <Spinner style={{ width: "3rem", height: "3rem" }} />}
    </Container>
  );
};

export default SSOAUTH;
