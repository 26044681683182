import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import GenerikFormComponent from "./GenerikFormComponent";
import { getMutation, getQuery, getUpdateMutation } from "../Helpers/mutation";
import {
  getData,
  getMutationVariables,
  getVariables,
} from "../Helpers/functions";
import { SETFINANTIALSTATUS } from "../GraphQl/mutation";
import { GET_UNITS_LIST } from "../GraphQl/query";
import { changeStatus } from "../Helpers/GenericListHelpers/genericListFunctions";
import { prepareValues, generateFields, generateInitValue } from "./functions";

const GenericFormContainer = ({
  toggleModal,
  label,
  structure,
  structureName,
  lastIndex,
  setValues,
  values,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [, setSpinnerStatus] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const validationSchema = Yup.object().shape({
    unit: Yup.string().required("Required"),
  });

  const { data: unitsData } = useQuery(GET_UNITS_LIST);
  const { id, orgVersionId: ogrID, structureId } = useParams();

  const calculationFields = structure.filter((item) =>
    Object.hasOwn(item, "calculation")
  );

  const initValue = structure
    .map((items) => items)
    .reduce((obj, item) => {
      obj[item.accessor] = generateInitValue(item, structureName);
      return obj;
    }, {});

  const mutation = values
    ? getUpdateMutation(structureName)
    : getMutation(structureName);

  const isFinancialPage = structureName === "Financial";
  const mutationName = mutation.definitions[0].name.value.toLocaleLowerCase();
  const isUpdateMutation = mutationName.includes("update");

  const [addRecord] = useMutation(mutation);

  const [doApprove] = useMutation(SETFINANTIALSTATUS, {
    onCompleted(data) {
      setValues(null);
      setSpinnerStatus(false);
    },
  });

  return (
    <>
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={
          Object.hasOwn(initValue, "unit") ? validationSchema : null
        }
        initialValues={{ ...initValue, ...values }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);
          setIsSubmitButtonDisabled(true);
          setErrorMessage("");
          try {
            let {
              comment,
              status,
              reasonCode,
              userComment = "",
              ...newVal
            } = values;
            newVal = JSON.parse(JSON.stringify(values));
            const valuesToSave = prepareValues(
              newVal,
              unitsData,
              isFinancialPage
            );
            await addRecord({
              variables: getMutationVariables(
                id,
                ogrID,
                valuesToSave,
                lastIndex,
                structureName,
                structureId,
                values.rowId
              ),
              ...(!isUpdateMutation && {
                update: (proxy, { data: { result } }) => {
                  const query = getQuery(structureName);
                  const variables = getVariables({
                    id: parseInt(id),
                    structureName: structureName,
                    ogrID: parseInt(ogrID),
                    structureId,
                  });

                  const data = proxy.readQuery({
                    query,
                    variables,
                  });
                  proxy.writeQuery({
                    query,
                    variables,
                    data: getData(data, result, structureName),
                  });
                },
              }),
            });

            if (isFinancialPage && isUpdateMutation && status)
              await changeStatus(
                setSpinnerStatus,
                doApprove,
                values.rowId,
                values.model.id,
                values.organizationVersion.id,
                "MODIFIED",
                comment,
                reasonCode,
                userComment
              );

            toggleModal(false);
          } catch (e) {
            setErrorMessage(e.message);
          } finally {
            setIsSubmitButtonDisabled(false);
            setValues(null);
          }
        }}
      >
        {(props) => (
          <>
            <GenerikFormComponent
              {...props}
              label={label}
              structure={structure}
              structureName={structureName}
              toggleModal={toggleModal}
              calculationFields={calculationFields}
              generateFields={generateFields}
              setValues={setValues}
              isSubmitButtonDisabled={isSubmitButtonDisabled}
            />
          </>
        )}
      </Formik>
      {errorMessage && (
        <>
          <div>
            <br />
            <br />
          </div>
          <div>{errorMessage}</div>
        </>
      )}
    </>
  );
};

export default GenericFormContainer;
