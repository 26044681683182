import { Spinner } from "reactstrap";
import i18n from "i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_LOGIN_DATA, getsubcategory2 } from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import { MODELS } from "../../navigation/Routes";
import { duplicateResponse } from "../Helpers/functions";

const SubCategoriesList = () => {
  const headers = ["models", "structureSubcategories"];

  const toModelList = (id, navigate) => {
    navigate(`${MODELS}/${id}`);
  };

  const toStructureSubcategory = (id) => {
    console.log(id);
  };

  const { id } = useParams();
  const categoryId = parseInt(id);

  const {
    data: auth,
    loading: authloading,
    error: authError,
  } = useQuery(GET_LOGIN_DATA);

  const { data, loading, error } = useQuery(getsubcategory2, {
    variables: { id: categoryId },
    fetchPolicy: "network-only",
    skip: !auth, // Prevents execution until auth is available
  });

  if (authloading || loading)
    return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (authError || error) return <div>{error.message}</div>;

  const parentValue = data.result[0] ? data.result[0].category.description : "";
  const subcategoryList = duplicateResponse(data.result);
  return (
    <GenericListContainer
      list={subcategoryList}
      structureName={"SubCategory"}
      parentName={"Category"}
      parentValue={parentValue}
      secondDetailsBtn={"To Structure"}
      btnLabel={"Create SubCategory"}
      detailsBtnLabel={i18n.t("toModels")}
      linkFunction={toModelList}
      secondLink={toStructureSubcategory}
      headers={headers}
    />
  );
};

export default SubCategoriesList;
