import PropTypes from "prop-types";

import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";

const showlist = (el, idx) => {
  if (el.desc)
    return (
      <option value={el.id} key={el.id}>
        {el.desc}
      </option>
    );
  return (
    <option value={el} key={idx}>
      {el}
    </option>
  );
};

const RowSelectInput = ({
  field: { ...fields },
  form: { touched, errors },
  labelcols,
  label,
  list,
  formGroupClass,
  ...props
}) => (
  <FormGroup row className={formGroupClass}>
    <Label xs={labelcols} for={fields.name} className="label-color text-left">
      {label}
    </Label>
    <Col xs={12 - labelcols}>
      <Input
        {...props}
        {...fields}
        type="select"
        invalid={touched[fields.name] && !!errors[fields.name]}
      >
        {list.map(showlist)}
      </Input>
      <FormFeedback>{errors[fields.name]}</FormFeedback>
    </Col>
  </FormGroup>
);

RowSelectInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired, // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number, // num colonne per etichetta
  list: PropTypes.oneOfType([
    // lista option: array di stringhe o di oggetti { id, desc }
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        desc: PropTypes.string,
      })
    ),
  ]),
  formGroupClass: PropTypes.string,
};

RowSelectInput.defaultProps = {
  labelcols: 3,
  formGroupClass: "",
};

export default RowSelectInput;
