import {Button, ModalHeader, Modal, ModalBody} from 'reactstrap';
import GenericFormContainer from "../GenerikForm/GenerikFormContainer";


const ModalComponent = ({
                            toggleModal,
                            isModalOpen,
                            label,
                            structure,
                            structureName,
                            lastIndex,
                            values,
                            setValues,
                            isUploadEnabled,
                            canAdd
                        }) => {
  
 

    return (
        <div>
            <Button hidden={!isUploadEnabled || !canAdd} onClick={() => toggleModal(true)}>{"Inserimento"}</Button>
            <Modal isOpen={isModalOpen} toggle={() => {toggleModal(false);setValues(null)}} size="lg">
                <ModalHeader toggle={() => {toggleModal(false);setValues(null)}}>{values? values.isDisabled?"Dettaglio":"Modifica":"Inserimento"}</ModalHeader>
                <ModalBody>
                    <GenericFormContainer label="Salva" structure={structure} structureName={structureName}
                                          lastIndex={lastIndex} toggleModal={toggleModal} setValues={setValues} values={values}/>
                </ModalBody>
            </Modal>

        </div>
    )
};

export default (ModalComponent);
