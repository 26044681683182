import { roles, MOH } from "./constants";
import {
  HOME,
  MOHPAGE,
  PRJS,
  PROJECTSBYSTRUCTUREYEAR,
} from "../../navigation/Routes";

//TODO more than one role
export function getRole(auth) {
  if (auth.user.roles) {
    const length = auth.user.roles.length;
    if (length <= 1) {
      return auth.user.roles[0].role === "EXTERNAL"
        ? "USER"
        : auth.user.roles[0].role;
    }
    var r = auth.user.roles.find((item) => {
      return (
        (item.role !== "USER" && roles.includes(item.role)) ||
        item.role === "EXTERNAL"
      );
    });
    return r.role === "EXTERNAL" ? "USER" : r.role;
  }
}

// Scelta HOME PAGE
// TODO per MOH pagina con lista Structure e poi va a PRJ filtrata x structure (orgver->ver->proj->structure)
export const redirect = (navigate, userRoles, instituteCode, code) => {
  //TODO: ATTENZIONE
  if (userRoles.includes(MOH)) navigate(MOHPAGE);
  else if (roles.includes(userRoles)) {
    // Esce PROJECT per Utente Normale
    // Esce ADMIN per SuperUser

    if (userRoles !== "PROJECT") instituteCode = -1;

    if (typeof code !== "undefined") {
      navigate(`${PROJECTSBYSTRUCTUREYEAR}/${code.id}/${code.year}`, {
        state: {
          refetch: true,
          code: code.code,
        },
      });
    } else {
      navigate(`${PRJS}/${instituteCode}`, {
        state: {
          refetch: true,
          userRoles: userRoles,
        },
      });
    }
  } else navigate(HOME);
};
