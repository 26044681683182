import PropTypes from "prop-types";

import { Col, FormGroup, Input, Label } from "reactstrap";

const RowCkeckInput = ({
  field: { name, value, ...fields },
  form: { touched, errors, setFieldValue, values },
  label,
  labelcols,
  formGroupClass,
  checkboxStyle,
  itemsFormCheckbox,
  ...props
}) => (
  <FormGroup row>
    <Label xs={labelcols} check for={name} className="label-check text-left">
      {" "}
      {label}
    </Label>
    <Col xs={12 - labelcols} className={itemsFormCheckbox}>
      <Input
        type="checkbox"
        style={checkboxStyle}
        name={name}
        checked={value == 1 || value === "true" || value === true}
        {...props}
        {...fields}
        invalid={touched[name] && !!errors[name]}
        onChange={(event) => {
          setFieldValue(name, event.target.checked);
        }}
      />
    </Col>
    <div className="label-check-error">{touched[name] && errors[name]}</div>
  </FormGroup>
);

RowCkeckInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name, value
  form: PropTypes.object.isRequired, // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  itemsFormCheckbox: PropTypes.string,
};

RowCkeckInput.defaultProps = {
  labelcols: 3,
};

export default RowCkeckInput;
