import GenericListContainer from "../GenericList/GenericListContainer";
import { useLocation, useParams } from "react-router-dom";
import { PROJECTSBYSTRUCTUREYEAR } from "../../navigation/Routes";

const StructureListYearsContainer = () => {
  // Mostra tutti gli anni che hanno almeno un progetto di tipo
  // specificato

  let { state } = useLocation();

  const { structureId } = useParams();

  let list = state.years;
  let prj_desc = state.prj_desc;

  let years = new Array();

  let i = 0;

  let structure_to_years_record = {};

  while (i < list.length) {
    if (list[i].structure_id === parseInt(structureId, 10)) {
      for (const year of list[i].years) {
        structure_to_years_record = {};

        structure_to_years_record.id = list[i].structure_id;

        structure_to_years_record.description = year;

        years.push(structure_to_years_record);
      }
    }

    i++;
  }

  years.sort((a, b) =>
    a.description.toString().localeCompare(b.description.toString())
  );

  const projectsByStructureYear = (id, year, navigate) => {
    navigate(`${PROJECTSBYSTRUCTUREYEAR}/${id}/${year}`, {
      state: {
        refetch: true,
        structureId: id,
        year: year,
        prj_desc: prj_desc,
      },
    });
  };

  return (
    <GenericListContainer
      list={years}
      structureName={"Structure"}
      detailsBtnLabel={"FiltraProgettiPerStrutturaAnno"}
      linkFunction={projectsByStructureYear}
      secondLink={projectsByStructureYear}
      secondDetailsBtn={"FiltraProgettiPerStrutturaAnno"}
      btnLabel={"Create Structure"}
      prj_desc={prj_desc}
    />
  );
};

export default StructureListYearsContainer;
