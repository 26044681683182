import { Spinner } from "reactstrap";
import { useQuery } from "@apollo/client";

import { GET_STRUCTURE_QUERY } from "../GraphQl/query";
import GenericListContainer from "../GenericList/GenericListContainer";
import { RULES, STRUCTURESUBCATEGORIESLIST } from "../../navigation/Routes";
import { duplicateResponse } from "../Helpers/functions";

const StructureList = () => {
  const headers = ["structureSubcategories", "validationRules"];

  const toStructureSubCategoryList = (id, navigate) => {
    navigate(`${STRUCTURESUBCATEGORIESLIST}/${id}`);
  };

  const toVerificationRules = (id, navigate) => {
    navigate(`${RULES}/${id}`);
  };

  const { loading, error, data } = useQuery(GET_STRUCTURE_QUERY);

  if (loading) return <Spinner style={{ width: "3rem", height: "3rem" }} />;
  if (error) return error.message;

  const structureList = duplicateResponse(data?.result);

  return (
    <GenericListContainer
      list={structureList}
      structureName={"Structure"}
      detailsBtnLabel={"Sottocategorie"}
      linkFunction={toStructureSubCategoryList}
      secondLink={toVerificationRules}
      secondDetailsBtn={"Regole di validazione"}
      btnLabel={"Create Structure"}
      headers={headers}
    />
  );
};

export default StructureList;
