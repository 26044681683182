import {useState, useEffect} from 'react';
import {Alert, Button, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import {useParams} from "react-router-dom";
import { FIND_PROJECT, GET_ORGANIZATION, GET_PROJECT_UNITS} from "../../GraphQl/query";
import {useMutation, useQuery} from "@apollo/client";
import {ADD_OPERATIVE_UNIT, CREATE_ORGANIZATION, UPDATE_PROJECT_UNIT} from "../../GraphQl/mutation";
const ProjectUnitModal = ({
                          modalState,
                          toggleModal,
                          translate,
                          list,
                          values,
                          setValues
                        
                      }) => {

    const {projectId} = useParams();
    const [projectUnit, setProjectUnit] = useState({});
    const [fiscalCode, setFiscalCode] = useState();

   
    const {loading: loadingProject, data: dataProject, error: errorProject} = useQuery(FIND_PROJECT, {
        variables: { projectId },
    });
    const {loading: loadingOrganization, data: dataOrganization, error: errorOrganization} = useQuery(GET_ORGANIZATION, {
        variables: { fiscalCode: fiscalCode},
        skip: !fiscalCode
    });

    useEffect(() => {
        if (values) {
            setProjectUnit({...values});
            setFiscalCode(values && values.organization? values.organization.fiscalCode:null)
        }
    }, [values]);

    useEffect(() => {
        if (dataProject) {
            const project = dataProject.result ? dataProject.result:{};
            const data = {...projectUnit};
            data.project = project;
            data.projectId = project.id;
            setProjectUnit(data);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProject, errorProject, loadingProject]);

    useEffect(() => {
        const data = {...projectUnit};
        if (dataOrganization) {
            const organization = dataOrganization.result ? dataOrganization.result : {};
            data.organization = organization;
            setProjectUnit(data);
        } else {
            data.organization = {};
            setProjectUnit(data);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOrganization, errorOrganization, loadingOrganization]);


    const [updateProjectUnit] = useMutation(UPDATE_PROJECT_UNIT);
    const [createProjectUnit] = useMutation(ADD_OPERATIVE_UNIT);

    const [saveOrganization] = useMutation(CREATE_ORGANIZATION)
    
    const getProjectUnitDataToSave = () => {
        var data = {
            projectId: projectId,
            coFinancing: projectUnit.coFinancing,
            cup: projectUnit.cup,
            organizationId: projectUnit.organization.id
        }
        return data;
    }
    const getOrganizationDataToSave = () => {
        var data = {
            projectId: projectId, 
            fiscalCode: (fiscalCode || '').trim(),
            instituteCode: (fiscalCode || '').trim(),
            description: projectUnit.organization ? projectUnit.organization.description : '',
            longDescription: projectUnit.organization ? projectUnit.organization.longDescription : '',
            legalRepresentativeName: projectUnit.organization ? projectUnit.organization.legalRepresentativeName : '',
            legalRepresentativeSurname: projectUnit.organization ? projectUnit.organization.legalRepresentativeSurname : '',
        }
        return data;
    }

    const hasOrganization = list.find(d => d.organization && d.organization.fiscalCode === (fiscalCode || '').trim())
    if(!projectUnit || !dataProject ) return null;
  
    return (
        <div>
            <Button onClick={() => {toggleModal(true); setValues(null); setProjectUnit({}); setFiscalCode('');}}>{"Inserimento"}</Button>
            <Modal isOpen={modalState} toggle={() => toggleModal(false)}>
                <ModalHeader>
                {(loadingProject || loadingOrganization) &&<Spinner className='me-2'></Spinner>} {translate("manageProjectUnit")} 
                </ModalHeader>
                <ModalBody>
                   
                    
                    <Label className='pt-2'><strong>{translate("project")}</strong></Label>
                    <Input 
                        type="text" 
                        placeholder={translate("project")} 
                        required 
                        defaultValue={dataProject && dataProject.result ? dataProject.result.code:""} 
                        disabled/>
                    <Label className='pt-2'><strong>{translate("fiscalCode")}</strong></Label>
                    <Input 
                        type="text" 
                        placeholder={translate("fiscalCode")} 
                        required 
                        defaultValue={fiscalCode || ""} 
                        disabled={values && (values.isDisabled || (values.organization && values.organization.fiscalCode))}
                        onBlur={(e) =>{setProjectUnit({...projectUnit, organization: {...projectUnit.organization, fiscalCode: e.target.value}}); setFiscalCode(e.target.value)}}/>
                        
                    {projectUnit.organization && projectUnit.organization.id && <Alert color="info" className='mt-2'> {translate("projectUnitAssociatedTo")}<strong>{projectUnit.organization.description}</strong> </Alert>}
                    {projectUnit.organization && !projectUnit.organization.id && !values &&
                    <>
                         <Alert color="warning" className='mt-2'>  {translate("createOrganization")} </Alert>
                         <Label className='pt-2'><strong>{translate("organizationDescription")}</strong></Label>

                            <Input 
                            type="text" 
                            placeholder={translate("organizationDescription")} 
                            required 
                            defaultValue={projectUnit.organization.description || ""} 
                            disabled={values && values.isDisabled}
                            onChange={(e) => setProjectUnit({...projectUnit, organization: {...projectUnit.organization, description: e.target.value}})}/>
                            
                        <Label className='pt-2'><strong>{translate("organizationLongDescription")}</strong></Label>

                        <Input 
                        type="text" 
                        placeholder={translate("organizationLongDescription")} 
                        required 
                        defaultValue={projectUnit.organization.longDescription || ""} 
                        disabled={values && values.isDisabled}
                        onChange={(e) => setProjectUnit({...projectUnit, organization: {...projectUnit.organization, longDescription: e.target.value}})}/>
                            
                        <Label className='pt-2'><strong>{translate("legalRepresentativeName")}</strong></Label>
                        <Input 
                        type="text" 
                        placeholder={translate("legalRepresentativeName")} 
                        required 
                        defaultValue={projectUnit.organization.legalRepresentativeName || ""} 
                        disabled={values && values.isDisabled}
                        onChange={(e) => setProjectUnit({...projectUnit, organization: {...projectUnit.organization, legalRepresentativeName: e.target.value}})}/>

                        <Label className='pt-2'><strong>{translate("legalRepresentativeSurname")}</strong></Label>
                        <Input 
                        type="text" 
                        placeholder={translate("legalRepresentativeSurname")} 
                        required 
                        defaultValue={projectUnit.organization.legalRepresentativeSurname || ""} 
                        disabled={values && values.isDisabled}
                        onChange={(e) => setProjectUnit({...projectUnit, organization: {...projectUnit.organization, legalRepresentativeSurname: e.target.value}})}/>
                    </>}
                    {hasOrganization && !values && <Alert color="danger">Impossibile associare un'altra unità all'organizzazione</Alert>}
                    {projectUnit.organization && projectUnit.organization.id && (!hasOrganization || values) &&
                    <>
                    
                        <Label className='pt-2'><strong>{translate("coFinancing")}</strong></Label>             
                        <InputGroup>
                            <Input  type="number"
                            placeholder={translate("coFinancing")}   
                            required 
                            disabled={values && values.isDisabled}
                            defaultValue={projectUnit?projectUnit.coFinancing:""} 
                            onChange={(e) => setProjectUnit({...projectUnit, coFinancing: e.target.value})}/>
                            <InputGroupText> € </InputGroupText>
                        </InputGroup>
                    
                    
                        <Label className='pt-2'><strong>{translate("cup")}</strong></Label>
                        <Input 
                            type="text" 
                            placeholder={translate("cup")} 
                            required 
                            disabled={values && values.isDisabled}
                            defaultValue={projectUnit?projectUnit.cup:""} 
                            onChange={(e) => setProjectUnit({...projectUnit, cup: e.target.value})}/>
                        </>
                      }
                </ModalBody>
                <ModalFooter>
                {projectUnit.organization && projectUnit.organization.id &&  !(values && values.isDisabled) &&  (!hasOrganization || values) &&
                    <Button onClick={() => {
                        const saveProjectUnit = values?.id ? updateProjectUnit : createProjectUnit;
                        saveProjectUnit({
                            variables: {
                                projectUnitId:projectUnit?.id,
                                data: getProjectUnitDataToSave(dataProject?.data)
                            },
                            refetchQueries: [{
                                query: GET_PROJECT_UNITS,
                                variables: { projectId: projectId },
                                awaitRefetchQueries: true,
                            }],
                        });
                        toggleModal(false)
                    }}>{translate("submitProjectUnit")}</Button>
                }
                    {projectUnit.organization && !projectUnit.organization.id &&  !(values && values.isDisabled)  &&
                    <Button onClick={() => {
                        saveOrganization({
                            variables: {
                                data: getOrganizationDataToSave()
                            },
                            update: (proxy, {data: {result}}) => {
                                const data = proxy.readQuery({
                                    query: GET_ORGANIZATION,
                                    variables: { fiscalCode: fiscalCode },
                                });
                                proxy.writeQuery({
                                    query: GET_ORGANIZATION,
                                    variables: { fiscalCode: fiscalCode },
                                    data: {
                                        ...data,
                                        result: result
                                    }
                                });
                            }
                        });
                    }}>{translate("submitOrganization")}</Button>
                }
                </ModalFooter>
            </Modal>
        </div>
    )
};
export default ProjectUnitModal;
