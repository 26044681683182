import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { getQuery } from "../Helpers/mutation";
import { useQuery } from "@apollo/client";
import { GET_LOGIN_DATA, GET_PRJ_INFO } from "../GraphQl/query";
import { duplicateResponse } from "../Helpers/functions";

const setOptions = (data, loading, error, structureName) => {
  if (loading) return [{ label: "Loading..." }];
  if (error) return [{ label: "Error..." }];
  if (data) {
    data.map((items) => delete items.__typename);
    switch (structureName) {
      case "Model":
      case "SubCategory":
      case "Unit":
        return data.map(({ id, organization, description }) => ({
          label: description ? description : organization.description,
          id: id,
        }));
      case "ReasonCode":
        return data
          .map((items) => ({
            label: `${items.code}: ${items.description}`,
            value: items.id,
          }))
          .sort((firsLabel, secondLabel) => {
            return sortLabels(firsLabel, secondLabel);
          });
      case "API":
      case "researchers":
        return data
          .map((items) => ({
            label: Object.values(items).join(" "),
            value: items,
          }))
          .sort((firsLabel, secondLabel) => {
            return sortLabels(firsLabel, secondLabel);
          });
      case "API_PROGETTO_RC":
        return data
          .map((items) => ({
            label: (`${items.id}: ${items.titolo}`|| '').substring(0, 50),
            value: items.id,
          }))
          .sort((firsLabel, secondLabel) => {
            return sortLabels(firsLabel, secondLabel);
          });
      default: {
        return data.map(({ id, description }) => ({
          label: description,
          id: id,
        }));
      }
    }
  }
};

const sortLabels = (firsLabel, secondLabel) => {
  const firsLabelToCompare = firsLabel.label.toUpperCase();
  const secondLabelToCompare = secondLabel.label.toUpperCase();
  if (firsLabelToCompare < secondLabelToCompare) {
    return -1;
  }
  if (firsLabelToCompare > secondLabelToCompare) {
    return 1;
  }
  return 0;
};

const setFValue = (
  inputValue,
  setFieldValue,
  structureName,
  fieldsName,
  setValue
) => {
  const splittedString = inputValue.split(" ");
  const code = splittedString.pop();
  const name = splittedString.toString().replace(",", " ");
  switch (structureName) {
    case "Model":
    case "SubCategory":
    case "Unit":
    case "ReasonCode":
      setValue(inputValue);
      break;
    case "API":
    case "researchers":
      setFieldValue("researcher_fiscal_code", code);
      setFieldValue("researcher_name", name);
      break;
    default: {
      setFieldValue(fieldsName, inputValue);
    }
  }
};

const showOption = (option) => {
  if (option) {
    return (
      <option value={option.label} key={option.id}>
        {option.label}
      </option>
    );
  }
  return (
    <option value={""} key={""}>
      {"option"}
    </option>
  );
};
const ApiSelectInput = ({
  name,
  label,
  labelCols,
  labelClass,
  structureName,
  onChange,
  inputProps: { defaultOption, options },
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { setFieldValue } = useFormikContext();
  const query = getQuery(structureName);
  const { data: projectInfo } = useQuery(GET_PRJ_INFO);
  const { data: userInfo } = useQuery(GET_LOGIN_DATA);
  const { data, loading, error } = useQuery(query, {
    variables: {
      year: projectInfo?.year - 1,
      projectCode: projectInfo?.externalProjectId,
      instituteCode: parseInt(userInfo?.userInfo.instituteCode),
      projectId: projectInfo?.projectId,
    },
  });
  const duplicatedData = data ? duplicateResponse(data?.result) : [];
  return (
    <FormGroup row>
      <Label
        xs={labelCols}
        for={field.name}
        className={`label-color text-left ${labelClass}`}
      >
        {label}
      </Label>
      <Col xs={12 - labelCols}>
        <Input
          id={field.name}
          {...field}
          {...props}
          type="select"
          invalid={meta.touched && meta.error}
          onChange={(event) =>
            setFValue(
              event.target.value,
              setFieldValue,
              structureName,
              field.name,
              setValue
            )
          }
        >
          <option>{defaultOption}</option>
          {setOptions(duplicatedData, loading, error, structureName)?.map(
            (option) => showOption(option, defaultOption)
          )}
        </Input>
        {meta.touched && meta.error && (
          <FormFeedback>{meta.error}</FormFeedback>
        )}
      </Col>
    </FormGroup>
  );
};

ApiSelectInput.propTypes = {
  name: PropTypes.string.isRequired, // field name
  label: PropTypes.string, // label text
  labelCols: PropTypes.number, // cols for label
  labelClass: PropTypes.string, // label csss class name
  inputProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        // list of options
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ])
    ),
  }).isRequired,
};

ApiSelectInput.defaultProps = {
  label: "",
  labelCols: 3,
  labelClass: "",
};

export default ApiSelectInput;
