import { isNumber } from "../Helpers/calculationFunctions";

export const prepareFinancialList = (data, statuses) => {
  const financialList = data.reduce(function (acc, row) {
    const found = acc.find((a) => a.rowId === row.rowId);
    if (found) {
      found[row.accessor] = row.value;
    } else {
      const r = { ...row };
      r[r.accessor] = r.value;
      delete r.accessor;
      delete r.value;
      acc.push(r);
    }
    return acc;
  }, []);
  if (statuses && statuses.length > 0) {
    return financialList.map((finItem) => {
      const status = statuses.find(
        (statusItem) => statusItem.rowId === finItem.rowId
      );
      if (status) {
        if (
          Object.hasOwn(status, "reasonCode") &&
          typeof status.reasonCode === "object" &&
          status.reasonCode !== null
        ) {
          const { code, description, id } = status.reasonCode;
          status.reasonCode = code;
          status.description = description;
          status.reasonCodeId = id;
        }
      }

      return { ...status, ...finItem };
    });
  } else {
    return financialList;
  }
};

export const prepareUnitList = (financialList, unitList) => {
  if (unitList && unitList.length > 0) {
    return financialList.map((finItem) => {
      const unit = unitList.find(
        (unitItem) => unitItem.id === parseInt(finItem.unit)
      );
      const description = unit ? unit.organization.description : null;
      if (description) {
        return { ...finItem, unit: description };
      }
      return finItem;
    });
  } else return financialList;
};

export const getTotals = (list, col) => {
  return list
    .filter((item) => item.status !== "REJECTED")
    .map((item) => isNumber(item[col]))
    .reduce((acc, red) => acc + red, 0);
};
export const getPreviousVersion = (list) => {
  return list.filter((item) => item.previous_version == 1);
};
