import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  Col, FormFeedback, FormGroup, Input, Label,
} from 'reactstrap';

const showOption = (option) => {
  if (option.description) {
    return (<option value={option.id} key={option.id}>{option.description}</option>);
  }
  return (<option value={option} key={option}>{option}</option>);
};

const FormSelectInput = ({
  name, label, labelCols, labelClass, inputProps: { defaultOption, options }, ...props
}) => {
  const [field, meta] = useField(name);
  return (
    <FormGroup row>
      <Label xs={labelCols} for={field.name} className={`label-color text-left ${labelClass}`} >{label}</Label>
      <Col xs={12 - labelCols}>
        <Input id={field.name} {...field} {...props} type="select" invalid={meta.touched && meta.error} >
          <option value="">{defaultOption}</option>
          {options?.map(showOption)}
        </Input>
        {meta.touched && meta.error && <FormFeedback>{meta.error}</FormFeedback>}
      </Col>
    </FormGroup>
  );
};

FormSelectInput.propTypes = {
  name: PropTypes.string.isRequired, // field name
  label: PropTypes.string, // label text
  labelCols: PropTypes.number, // cols for label
  labelClass: PropTypes.string, // label csss class name
  inputProps: PropTypes.shape({
    defaultOption: PropTypes.string, // default select option
    options: PropTypes.arrayOf(PropTypes.oneOfType([ // list of options
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
    ])),
  }).isRequired,
};

FormSelectInput.defaultProps = {
  label: '',
  labelCols: 3,
  labelClass: '',
};

export default FormSelectInput;
