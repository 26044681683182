import { Col, Button, Form, Row } from "reactstrap";

const GenericFormComponent = ({
  isSubmitting,
  handleSubmit,
  label,
  values,
  structure,
  generateFields,
  structureName,
  calculationFields,
  toggleModal,
  setValues,
  isSubmitButtonDisabled,
}) => {
  let fields = structure.map((items, index) =>
    generateFields(items, index, structureName, calculationFields, values)
  );
  fields = fields.filter((obj) => obj.props.name !== "status");

  return (
    <>
      <div>
        <Form>
          {fields}
          <Row>
            <Col className="text-md-left pt-1 btn-confirm">
              {
                <Button
                  color="success"
                  disabled={isSubmitting || isSubmitButtonDisabled}
                  hidden={values.isDisabled}
                  onClick={handleSubmit}
                >
                  {label}
                </Button>
              }
            </Col>
            <Col>
              {
                <Button
                  onClick={() => {
                    toggleModal(false);
                    setValues(null);
                  }}
                >
                  Annulla
                </Button>
              }
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default GenericFormComponent;
