import { Input } from "reactstrap";

import {
  OrgStatusFilter,
  StatusFilter,
  BooleanFilterNew,
  BooleanDefaultFilterNew,
} from "../Helpers/filters";
import { FILTER_TYPES } from "../Helpers/constants";

const FILTER_COMPONENTS = {
  [FILTER_TYPES.orgStatusFilter]: OrgStatusFilter,
  [FILTER_TYPES.statusFilter]: StatusFilter,
  [FILTER_TYPES.boolean]: BooleanFilterNew,
  [FILTER_TYPES.booleanDefault]: BooleanDefaultFilterNew,
};

export const ColumnFilter = ({ column }) => {
  const columnFilterValue = column?.getFilterValue() ?? "";
  const { filterVariant } = column.columnDef ?? {};

  const FilterComponent = FILTER_COMPONENTS[filterVariant];

  if (FilterComponent) {
    return (
      <FilterComponent
        filterValue={columnFilterValue.toString()}
        column={column}
      />
    );
  }

  // Default filter input
  return (
    <Input
      className="w-36 border shadow rounded"
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder=""
      type="text"
      value={columnFilterValue}
    />
  );
};
