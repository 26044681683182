import { useState } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { format } from "date-fns";

import { GET_PROJECT_VERSION } from "../../GraphQl/query";
import {
  CREATE_PROJECT_VERSION,
  UPDATE_PROJECT_VERSION,
} from "../../GraphQl/mutation";
import RowInput from "../../FormElements/RowInput";
import RowSelectAutocompleteInput from "../../FormElements/RowSelectAutocompleteInput";
import RowDatePickerInput from "../../FormElements/RowDatePickerInput";

import {
  getMinStartDate,
  getMaxStartDate,
  getDefaultEndDate,
  getMinEndDate,
  getMaxEndDate,
} from "./functions";

import "react-datepicker/dist/react-datepicker.css";

export const ProjectVersionModal = ({
  modalState,
  toggleModal,
  translate,
  list,
  values,
  isUpdate = false,
}) => {
  const { projectId } = useParams();
  const [projectVersion] = useState({});

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Required"),
    versionType: Yup.string().required("Required"),
  });

  const [errorMessage, setErrorMessage] = useState("");

  const minStartDate = getMinStartDate(list, values);
  const maxStartDate = getMaxStartDate(values);
  const minEndDate = getMinEndDate(values);
  const maxEndDate = getMaxEndDate(values);

  const versionOptions = [
    { value: "", label: translate("selectVerionType") },
    { value: "FINAL", label: translate("FINAL") },
  ];

  if (
    values?.project &&
    values?.project.structure &&
    values?.project.structure.hasInterim
  ) {
    versionOptions.push({ value: "INTERIM", label: translate("INTERIM") });
  }

  const handleSubmit = (values) => {
    saveProjectVersion({
      variables: {
        projectVersionId: values?.id,
        data: getProjectVersionDataToSave(values),
      },
      update: (proxy, { data: { result } }) => {
        const data = proxy.readQuery({
          query: GET_PROJECT_VERSION,
          variables: {
            projectId,
            projectVersionId: projectVersion.id,
          },
        });
        proxy.writeQuery({
          query: GET_PROJECT_VERSION,
          variables: {
            projectId: projectId,
            projectVersionId: projectVersion.id,
          },
          data: {
            ...data,
            result: isUpdate ? data.result : [...data.result, result],
          },
        });
      },
    })
      .then(() => {
        toggleModal(false);
        setErrorMessage("");
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const getMutation = () => {
    return isUpdate ? UPDATE_PROJECT_VERSION : CREATE_PROJECT_VERSION;
  };

  const [saveProjectVersion] = useMutation(getMutation(), {
    onCompleted(data) {},
  });

  const getProjectVersionDataToSave = (values) => {
    const end = getDefaultEndDate(values);
    const data = {
      active: values.active,
      projectId: values.project.id,
      description: values.description,
      endPeriodDate: format(end, "yyyy-MM-dd") + " 12:00:00",
      startPeriodDate:
        format(values.startPeriodDate, "yyyy-MM-dd") + " 12:00:00",
      versionType: values.versionType,
    };
    return data;
  };

  var hasFinal = !isUpdate && list.find((d) => d.versionType === "FINAL");

  if (!values) return null;
  return (
    <div>
      <Modal isOpen={modalState} toggle={() => toggleModal(false)}>
        <ModalHeader>{translate("manageProjectVersion")}</ModalHeader>
        {hasFinal && (
          <Alert color="red">
            {translate(
              "Esiste già una rendicontazione finale, non ne puoi aggiungere altre"
            )}
          </Alert>
        )}
        {!hasFinal && values && (
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({ values, errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <>
                    <Field
                      disabled
                      label={translate("project")}
                      name={"project.code"}
                      component={RowInput}
                    />
                    <Field
                      label={translate("description")}
                      name={"description"}
                      component={RowInput}
                    />
                    <Field
                      label={translate("versionType")}
                      name={"versionType"}
                      list={versionOptions}
                      component={RowSelectAutocompleteInput}
                    />
                    {values.versionType === "INTERIM" && (
                      <>
                        <Field
                          label={translate("startPeriodDate")}
                          name={"startPeriodDate"}
                          showIcon
                          minDate={minStartDate}
                          maxDate={maxStartDate}
                          component={RowDatePickerInput}
                        />
                        <Field
                          label={translate("endPeriodDate")}
                          name={"endPeriodDate"}
                          showIcon
                          minDate={minEndDate}
                          maxDate={new Date(maxEndDate)}
                          component={RowDatePickerInput}
                        />
                      </>
                    )}
                  </>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={
                      values.versionType !== "FINAL" &&
                      values.versionType !== "INTERIM"
                    }
                  >
                    {translate("submitProjectVersion")}
                  </Button>
                  <Button
                    onClick={() => {
                      setErrorMessage("");
                      toggleModal(false);
                    }}
                  >
                    {"Annulla"}
                  </Button>

                  {errorMessage && <div>{errorMessage}</div>}
                </ModalFooter>
              </form>
            )}
          </Formik>
        )}
      </Modal>
    </div>
  );
};
