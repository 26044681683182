import { useState, createContext } from 'react';

const ProjectContext = createContext();

const ProjectProvider = ({children}) => {
    const [project, setProject] = useState('');

    return (<ProjectContext.Provider value={{project, setProject}}>
        {children}
    </ProjectContext.Provider>);
};

export default ProjectProvider;

const ProjectConsumer = ProjectProvider.Consumer;
export {ProjectConsumer, ProjectContext }