import {
    CREATE_CATEGORY_MUTATION,
    CREATE_MODEL_MUTATION,
    CREATE_SUBCATEGORY_MUTATION,
    UPDATE_CATEGORY,
    UPDATE_MODEL,
    UPDATE_SUBCATEGORY,
    SAVEFINANCIAL,
    DELETE_FINANCIAL,
    DELETE_CATEGORY,
    CREATEREASONCODE, 
    UPDATEREASONCODE, 
    DELETEREASONCODE,
    DELETE_SUBCATEGORY,
    DELETE_MODEL,
    UPDATEFINANCIAL,
    EMPTY,
    CREATE_STRUCTURE,
    CREATE_STRUCTURE_SUBCATEGORY,
    CREATE_RULE,
    UPDATE_STRUCTURE,
    UPDATE_STRUCTURESUBCATEGORY,
    UPDATE_VALIDATION_RULE,
    DISABLE_STRUCTURE,
    DISABLE_STRUCTURESUBCATEGORY, 
    DISABLE_VALIDATION_RULE, 
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
    UPDATE_PROJECT_UNIT,
    CREATE_PROJECT_VERSION,
    UPDATE_PROJECT_VERSION
} from "../GraphQl/mutation";
import {
    GET_CATEGORIES, GET_EQUIPMENT,
    GET_FINANCIAL,
    GET_FINANCIALITEM, GET_MODELS,
    GET_ORG_VERSION,
    GET_STRUCTURE, GET_STRUCTURE_QUERY, GET_STRUCTURE_SUBCATEGORY,
    GET_SUBCATEGORY_TOTALS, GET_UNITS_LIST, GETREASONCODE, getsubcategory2, RESEARCHERS, RESEARCHERSV2, RULES,
    GET_PROGETTILINEARC_QUERY,
} from "../GraphQl/query";


export const getMutation = (name) => {
    switch (name) {
        case "Category":
            return CREATE_CATEGORY_MUTATION;
        case "SubCategory":
            return CREATE_SUBCATEGORY_MUTATION;
        case "Model":
            return CREATE_MODEL_MUTATION;
        case "ReasonCode":
            return CREATEREASONCODE;
        case "Structure":
            return CREATE_STRUCTURE;
        case "StructureSubcategory":
            return CREATE_STRUCTURE_SUBCATEGORY;
        case "ValidationRule":
            return CREATE_RULE;
        case "User":
            return CREATE_USER;
        case "ProjectVersion":
            return CREATE_PROJECT_VERSION;
        case "Financial":
        case "Project":
        case "SubCategoryTotalDto":
            return SAVEFINANCIAL;
        default:
            return EMPTY
    }
};

export const getQuery = (name) => {
    switch (name) {
        case "Category":
            return GET_CATEGORIES;
        case "SubCategory":
            return getsubcategory2;
        case "Model":
            return GET_MODELS;
        case "Financial":
            return GET_FINANCIAL;
        case "Project":
            return GET_ORG_VERSION;
        case "SubCategoryTotalDto":
            return GET_SUBCATEGORY_TOTALS;
        case "ReasonCode":
            return GETREASONCODE;
        case "Structure":
            return GET_STRUCTURE_QUERY;
        case "StructureSubcategory":
            return GET_STRUCTURE_SUBCATEGORY;
        case "ValidationRule":
            return RULES;
        case "API":
            return RESEARCHERS;
        case "researchers":
            return RESEARCHERSV2
        case "Unit":
        case "unit":
            return GET_UNITS_LIST
        case "equipment":
            return GET_EQUIPMENT
        case "API_PROGETTO_RC":
            return GET_PROGETTILINEARC_QUERY
        default:
            return EMPTY
    }
};

export const getUpdateMutation = (name) => {
    switch (name) {
        case "Category":
            return UPDATE_CATEGORY;
        case "SubCategory":
            return UPDATE_SUBCATEGORY;
        case "Model":
            return UPDATE_MODEL;
        case "Structure":
            return UPDATE_STRUCTURE;
        case "StructureSubcategory":
            return UPDATE_STRUCTURESUBCATEGORY;
        case "ValidationRule":
            return UPDATE_VALIDATION_RULE;
        case "ReasonCode":
            return UPDATEREASONCODE;
        case "User":
            return UPDATE_USER;
        case "ProjectUnits": 
            return UPDATE_PROJECT_UNIT;
        case "ProjectVersion":
            return UPDATE_PROJECT_VERSION
        case "Financial":
        case "Project":
        case "SubCategoryTotalDto":
            return UPDATEFINANCIAL;
        default:
            return EMPTY
    }
};
export const getDeleteMutation = (name) => {
    switch (name) {
        case "Category":
            return DELETE_CATEGORY;
        case "SubCategory":
            return DELETE_SUBCATEGORY;
        case "Model":
            return DELETE_MODEL;
        case "Financial":
        case "Project":
        case "SubCategoryTotalDto":
            return DELETE_FINANCIAL;
        case "ReasonCode":
            return DELETEREASONCODE;
        case "Structure":
            return DISABLE_STRUCTURE;
        case "StructureSubcategory":
            return DISABLE_STRUCTURESUBCATEGORY;
        case "ValidationRule":
            return DISABLE_VALIDATION_RULE;
        case "User":
            return DELETE_USER;
        default:
            return EMPTY
    }
};

export const getStructureQuery = (name) => {
    switch (name) {
        case "Category":
        case "SubCategory":
        case "Model":
        case "Project":
        case "SubCategoryTotalDto":
        case "ReasonCode":
        case "Structure":
        case "StructureSubcategory":
        case "ValidationRule":
        case "ProjectVersion":
        case "OrganizationVersion":
        case "StructureMOHStructure":
        case "ProjectUnits":
            return GET_STRUCTURE;
        case "Financial":
            return GET_FINANCIALITEM;
        default:
            return EMPTY
    }
};
