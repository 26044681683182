import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider, useQuery } from "@apollo/client";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AppRoutes from "../../navigation/AppRoutes";
import { client } from "../GraphQl/index";
import { i18nTrans, lang } from "../resources";
import Home from "../Home";
import { GET_LOGIN_DATA } from "../GraphQl/query";
import NavbarComponent from "../Navbar/NavbarComponent";
import { useLocation } from "react-router-dom";
import { LOGINSSO } from "../../navigation/Routes";
import SSOAUTH from "../AuthSSO/index";
import ProjectProvider from "../ProjectProvider";

const env = process.env.NODE_ENV || "development";

i18n.use(initReactI18next).init({
  resources: i18nTrans,
  lng: lang,
});

function IsLoggedIn() {
  const { data: isLoggedIn } = useQuery(GET_LOGIN_DATA);
  const location = useLocation();
  const isLoginSSO =
    location.pathname.match(RegExp(LOGINSSO)) ||
    (env === "development" &&
      (location.pathname === "/" || location.pathname === "/login"));
  if (isLoggedIn)
    return isLoggedIn.isLoggedIn ? (
      <AppRoutes />
    ) : isLoginSSO ? (
      <SSOAUTH />
    ) : (
      <Home />
    );
  else return isLoginSSO ? <SSOAUTH /> : <Home />;
}

const AppComponent = () => {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <I18nextProvider lng="en" i18n={i18n}>
          <ProjectProvider>
            <Router future={{ v7_startTransition: true }}>
              <NavbarComponent />
              <IsLoggedIn />
            </Router>
          </ProjectProvider>
        </I18nextProvider>
      </ApolloProvider>
    </div>
  );
};

AppComponent.propTypes = {};

AppComponent.defaultProps = {};

export default AppComponent;
